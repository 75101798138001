export default {
  type: "inspection-form-reminders",
  order: 0,
  excerpt: "",
  relationshipNames: ["inspectionForm"],
  inspectionForm: {
    type: "inspection-forms",
    id: null,
  },
};
