<template>
  <div>
    <base-button
      @click="$refs.fieldsFileInput.click()"
      type="button"
      class="btn btn-sm btn-primary"
    >
      <span> {{ $t("INSPECTION_FORMS.SYNC") }} </span>
      <input
        style="display: none"
        type="file"
        id="inspectionform-file-input"
        ref="fieldsFileInput"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        @change="syncFieldsDry"
      />
    </base-button>

    <modal
      :show.sync="showResultsModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <div>
        <h2>
          {{
            isDry
              ? $t("INSPECTION_FORMS.SYNC_PREVIEW")
              : $t("INSPECTION_FORMS.SYNC_RESULTS")
          }}
          <base-button
            v-if="isDry"
            @click="runSync"
            type="button"
            class="btn btn-sm btn-primary text-uppercase px-3"
            style="width: auto"
          >
            {{ $t("INSPECTION_FORMS.RUN_SYNCHRONISATION") }}
          </base-button>
        </h2>
        <div>
          <el-table
            :data="synchronisationResult"
            style="width: 100%"
            type="expand"
            row-key="id"
          >
            <el-table-column
              prop="line"
              :label="$t('INSPECTION_FORMS.FIELDS_UNTOUCHED')"
            >
              <template v-slot="{ row }">
                {{ `${row.untouched}` }}
              </template>
            </el-table-column>
            <el-table-column
              prop="line"
              :label="$t('INSPECTION_FORMS.FIELDS_CREATED')"
            >
              <template v-slot="{ row }">
                {{ `${row.added}` }}
              </template>
            </el-table-column>
            <el-table-column
              prop="line"
              :label="$t('INSPECTION_FORMS.FIELDS_UPDATED')"
            >
              <template v-slot="{ row }">
                {{ `${row.updated}` }}
              </template>
            </el-table-column>
            <el-table-column
              prop="line"
              :label="$t('INSPECTION_FORMS.FIELDS_DELETED')"
            >
              <template v-slot="{ row }">
                {{ `${row.deleted}` }}
              </template>
            </el-table-column>
            <el-table-column
              prop="line"
              :label="$t('INSPECTION_FORMS.FIELDS_ERRORS')"
            >
              <template v-slot="{ row }">
                {{ `${row.errors.length}` }}
              </template>
            </el-table-column>

            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table
                  :data="props.row.errors"
                  style="width: 100%"
                  type="expand"
                  row-key="id"
                >
                  <el-table-column
                    prop="id"
                    :label="$t('INSPECTION_FORMS.LINE')"
                  >
                    <template v-slot="{ row }">
                      {{ `${row.name}` }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="name"
                    :label="$t('INSPECTION_FORMS.ERROR')"
                  >
                    <template v-slot="{ row }">
                      {{ row.error }}
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            layout="prev, pager, next"
            :total="synchronisationResult.length"
          ></el-pagination>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import swal from "sweetalert2";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Pagination,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  name: "inspection-form-view-sync-fields",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Pagination.name]: Pagination,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["inspectionForm"],

  data() {
    return {
      showFileModal: false,
      showResultsModal: false,
      isDry: true,
      synchronisationResult: [],
      fieldsFile: null,
    };
  },

  created() {},

  methods: {
    async syncFieldsDry(e) {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      e.target.value = "";

      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        await this.$store.dispatch("files/upload", {
          image: file,
          ressource_name: "inspection-fields",
          ressource_id: this.inspectionForm.id,
          field: "files",
        });
        this.fieldsFile = await this.$store.getters["files/url"];

        const results = await this.$store.dispatch(
          "inspectionForms/syncFieldsDryRun",
          {
            file: this.fieldsFile,
            id: this.inspectionForm.id,
          }
        );
        this.isDry = true;
        this.synchronisationResult = [results];
        this.showResultsModal = true;
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
      }
    },

    async runSync() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const results = await this.$store.dispatch(
          "inspectionForms/syncFieldsRun",
          {
            file: this.fieldsFile,
            id: this.inspectionForm.id,
          }
        );
        this.synchronisationResult = [results];
        this.isDry = false;
        this.showResultsModal = true;
        this.$emit("onInspectionSynched");
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
      }
    },
  },
};
</script>
