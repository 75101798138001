<template>
  <el-select
    v-model="fuelTypesModel"
    @change="fuelTypesChanged"
    :placeholder="$t('VEHICLES.FUEL_TYPE')"
    :filterable="filterable"
    :disabled="disabled"
    multiple
  >
    <el-option
      v-for="fuel in fuelTypesOptions"
      :key="fuel.value"
      :value="fuel.value"
      :label="fuel.label"
    >
    </el-option>
  </el-select>
</template>

<script>
import _ from "lodash";
import { Option, Select } from "element-ui";
import { fuelTypes } from "@/constants/fuelTypes";

export default {
  name: "fuel-types-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    value: {
      type: Array | null | undefined,
      default: "",
      description: "Value",
    },
  },

  data() {
    let fuelTypesModel = null;
    if (this.value) {
      fuelTypesModel = this.value?.map((value) => value?.toLowerCase());
    }
    return {
      fuelTypesModel,
      fuelTypes,
    };
  },

  setup() {},

  created() {},

  computed: {
    fuelTypesOptions: function () {
      return Object.entries(this.fuelTypes).map(([key, value]) => ({
        value,
        label: this.$t(`FUEL_TYPES.${key}`),
      }));
    },
  },

  methods: {
    fuelTypesChanged(fuelTypes) {
      this.$emit("fuelTypesChanged", fuelTypes);
    },
  },

  watch: {
    value() {
      this.fuelTypesModel = this.value?.map((value) => value?.toLowerCase());
    },
  },
};
</script>
