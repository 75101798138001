<template>
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <form class="row" @submit.prevent="handleSubmit">
      <div class="mt-2 col-md-6">
        <base-input
          :label="`${$t(
            'INSPECTION_FORMS.INSPECTION_FORM_NOTE_MIN_VALUE'
          )} (*)`"
          :placeholder="$t('INSPECTION_FORMS.INSPECTION_FORM_NOTE_MIN_VALUE')"
          v-model="note.min_value"
          input-classes="form-control-alternative"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.min_value" />
      </div>
      <div class="mt-2 col-md-6">
        <base-input
          :label="$t('INSPECTION_FORMS.INSPECTION_FORM_NOTE_MAX_VALUE')"
          :placeholder="$t('INSPECTION_FORMS.INSPECTION_FORM_NOTE_MAX_VALUE')"
          v-model="note.max_value"
          input-classes="form-control-alternative"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.max_value" />
      </div>
      <div class="mt-2 col-md-12">
        <base-input
          :label="`${$t('INSPECTION_FORMS.INSPECTION_FORM_NOTE')} (*)`"
          :placeholder="$t('INSPECTION_FORMS.INSPECTION_FORM_NOTE')"
          v-model="note.note"
          input-classes="form-control-alternative"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.note" />
      </div>
    </form>
    <template slot="footer">
      <button
        type="submit"
        class="btn btn-primary"
        @click="handleSubmit"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        <span v-if="!noteData">
          {{ $t("COMMON.ADD") }}
        </span>
        <span v-else>
          {{ $t("COMMON.UPDATE") }}
        </span>
      </button>
      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closeModal"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import defaultNote from "./defaultInspectionFormNotes.js";

export default {
  name: "inspection-form-note-form-modal",

  components: { ValidationError },

  mixins: [formMixin],

  props: ["noteData", "showModal", "loading"],

  data() {
    const note = this.noteData
      ? cloneDeep(this.noteData)
      : cloneDeep(defaultNote);

    const exampleNote = { ...defaultNote };
    return {
      note,
      exampleNote,
      formErrors: null,
    };
  },

  computed: {},

  created() {},

  methods: {
    closeModal() {
      this.$emit("onCloseModal");
      this.note = cloneDeep(defaultNote);
    },
    handleSubmit() {
      this.$emit("noteFormSubmitted", this.note);
    },
  },

  mounted() {},

  watch: {
    showModal(showModal) {
      if (!showModal) {
        this.formErrors = null;
        this.loading = false;
        this.note = cloneDeep(defaultNote);
      }
    },
    noteData() {
      if (this.noteData) {
        this.note = cloneDeep(this.noteData);
      }
    },
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
