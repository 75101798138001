<template>
  <div
    v-if="showModal"
    class="resizable-wrapper"
    v-bind:class="[showModal ? 'show' : 'hide']"
  >
    <div
      class="resizable-wrapper-content"
      v-resizable-modal="'INSPECTION_FORM_SECTION_FIELD'"
      @onCloseModal="closeInspectionFormSectionFieldModal"
    >
      <div class="resizable-wrapper-content-header">
        <div class="resizable-wrapper-content-header-left">
          <base-button
            icon
            size="sm"
            class="back-button"
            @click="closeInspectionFormSectionFieldModal"
          >
            <i class="fal fa-arrow-left"></i>
          </base-button>
          <h1 v-if="!inspectionFormSectionField.id">
            {{ $t("INSPECTION_FORMS.NEW_INSPECTION_FORM_SECTION_FIELD") }}
          </h1>
          <h1 v-if="!!inspectionFormSectionField.id">
            {{ $t("INSPECTION_FORMS.EDIT_INSPECTION_FORM_SECTION_FIELD") }}
          </h1>
        </div>
        <div class="resizable-wrapper-content-header-right">
          <button class="btn expand">
            <img
              src="../../../../../../../public/img/sm-arrows-expand.svg"
              alt="icon"
            />
          </button>
        </div>
      </div>
      <div class="resizable-wrapper-content-body">
        <div class="section-form-wrapper">
          <div class="inspection-form">
            <div class="inspection-form-group width-1">
              <div class="checkbox-list">
                <base-checkbox
                  v-model="inspectionFormSectionFieldModel.is_copy"
                  :disabled="!!inspectionFormSectionFieldModel.id"
                >
                  <span class="form-control-label">
                    {{ $t("INSPECTION_FORMS.IS_COPY") }}
                  </span>
                </base-checkbox>
                <validation-error :errors="apiValidationErrors.is_copy" />
              </div>
            </div>
            <div
              v-if="inspectionFormSectionFieldModel.is_copy"
              class="inspection-form-group width-1"
            >
              <base-input
                :label="`${$t('INSPECTION_FORMS.COPIED_FIELD')} (*)`"
                input-classes="form-control-alternative"
              >
                <inspection-form-section-field-selector
                  :field="inspectionFormSectionFieldModel.copiedField?.id"
                  :filterInspectionForm="inspectionForm.id"
                  :disabled="!!inspectionFormSectionFieldModel.id"
                  :showAll="false"
                  :allowNone="false"
                  @fieldChanged="
                    (fieldId) =>
                      (inspectionFormSectionFieldModel.copiedField.id = fieldId)
                  "
                />
              </base-input>
              <validation-error :errors="apiValidationErrors.copiedField" />
            </div>

            <div
              v-if="!inspectionFormSectionFieldModel.is_copy"
              class="inspection-form-group width-1"
            >
              <base-input
                :label="`${$t('COMMON.NAME')} (*)`"
                :placeholder="$t('COMMON.NAME')"
                v-model="inspectionFormSectionFieldModel.name"
                input-classes="form-control-alternative"
              >
              </base-input>
              <validation-error :errors="apiValidationErrors.name" />
            </div>
            <div
              v-if="!inspectionFormSectionFieldModel.is_copy"
              class="inspection-form-group width-1"
            >
              <base-input
                :label="`${$t('COMMON.EXCERPT')} (*)`"
                :placeholder="`${$t('COMMON.EXCERPT')} (*)`"
                input-classes="form-control-alternative"
              >
                <html-editor
                  v-model="inspectionFormSectionFieldModel.excerpt"
                  :name="`${$t('COMMON.EXCERPT')} (*)`"
                />
              </base-input>
              <validation-error :errors="apiValidationErrors.excerpt" />
            </div>
            <div
              v-if="!inspectionFormSectionFieldModel.is_copy"
              class="inspection-form-group width-1"
            >
              <base-input
                :label="`${$t('COMMON.TYPE')} (*)`"
                :placeholder="`${$t('COMMON.TYPE')} (*)`"
              >
                <el-select
                  name="FieldType"
                  v-model="inspectionFormSectionFieldModel.field_type"
                  @change="
                    () => {
                      inspectionFormSectionFieldModel.points_threshold = [];
                    }
                  "
                >
                  <el-option
                    v-for="(key, value) in inspectionFormFieldOptions"
                    :key="key"
                    :value="value"
                    :label="$t(key)"
                  />
                </el-select>
              </base-input>
              <validation-error :errors="apiValidationErrors.field_type" />
            </div>
            <div
              class="inspection-form-group width-1"
              v-if="
                !inspectionFormSectionFieldModel.is_copy &&
                inspectionFormSectionFieldModel.field_type ===
                  INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET
              "
            >
              <div class="checkbox-list">
                <base-checkbox
                  v-model="inspectionFormSectionFieldModel.enable_na"
                >
                  <span class="form-control-label">
                    {{ $t("INSPECTION_FORMS.ENABLE_NA") }}
                    <span class="check">
                      <i class="far fa-check"></i>
                    </span>
                  </span>
                </base-checkbox>
                <validation-error :errors="apiValidationErrors.enable_na" />
              </div>
            </div>
            <div
              class="inspection-form-group width-1"
              v-if="
                !inspectionFormSectionFieldModel.is_copy &&
                inspectionFormSectionFieldModel.field_type
              "
            >
              <base-input>
                <inspection-form-section-field-form-threshold
                  :fieldType="inspectionFormSectionFieldModel.field_type"
                  :enableNa="inspectionFormSectionFieldModel.enable_na"
                  :thresholdData="
                    inspectionFormSectionFieldModel.points_threshold
                  "
                  @onThresholdChanged="
                    (data) => {
                      inspectionFormSectionFieldModel.points_threshold = data;
                    }
                  "
                />
              </base-input>
              <validation-error :errors="apiValidationErrors.field_type" />
            </div>
            <div
              v-if="!inspectionFormSectionFieldModel.is_copy"
              class="inspection-form-group width-1"
            >
              <base-input
                :label="`${$t('INSPECTION_FORMS.MEAN_REPAIR_VALUE')} (*)`"
                :placeholder="`${$t('INSPECTION_FORMS.MEAN_REPAIR_VALUE')} (*)`"
                input-classes="form-control-alternative"
                v-model="inspectionFormSectionFieldModel.mean_repair_value"
              >
              </base-input>
              <validation-error
                :errors="apiValidationErrors.mean_repair_value"
              />
            </div>
            <div
              v-if="!inspectionFormSectionFieldModel.is_copy"
              class="inspection-form-group width-1"
            >
              <div class="checkbox-list">
                <base-checkbox
                  v-model="inspectionFormSectionFieldModel.can_upload_image"
                >
                  <span class="form-control-label">
                    {{ $t("INSPECTION_FORMS.CAN_UPLOAD_IMAGE") }}
                    <span class="check">
                      <i class="far fa-check"></i>
                    </span>
                  </span>
                </base-checkbox>
                <validation-error
                  :errors="apiValidationErrors.can_upload_image"
                />

                <base-checkbox
                  v-model="inspectionFormSectionFieldModel.can_add_comment"
                >
                  <span class="form-control-label">
                    {{ $t("INSPECTION_FORMS.CAN_ADD_COMMENT") }}
                    <span class="check">
                      <i class="far fa-check"></i>
                    </span>
                  </span>
                </base-checkbox>
                <validation-error
                  :errors="apiValidationErrors.can_add_comment"
                />
              </div>
            </div>
            <div
              v-if="!inspectionFormSectionFieldModel.is_copy"
              class="inspection-form-group width-1"
            >
              <div class="checkbox-list">
                <base-checkbox
                  v-model="
                    inspectionFormSectionFieldModel.impairs_proper_functionning
                  "
                >
                  <span class="form-control-label">
                    {{ $t("INSPECTION_FORMS.IMPAIRS_PROPER_FUNCTIONNING") }}
                    <span class="check">
                      <i class="far fa-check"></i>
                    </span>
                  </span>
                </base-checkbox>
                <validation-error
                  :errors="apiValidationErrors.impairs_proper_functionning"
                />

                <base-checkbox v-model="inspectionFormSectionFieldModel.major">
                  <span class="form-control-label">
                    {{ $t("INSPECTION_FORMS.MAJOR") }}
                    <span class="check">
                      <i class="far fa-check"></i>
                    </span>
                  </span>
                </base-checkbox>
                <validation-error :errors="apiValidationErrors.major" />
              </div>
            </div>
            <div
              v-if="!inspectionFormSectionFieldModel.is_copy"
              class="inspection-form-group width-1"
            >
              <image-selector
                ressource_name="inspection-form-field"
                :defaultImage="inspectionFormSectionFieldModel.icon"
                :ressource_id="
                  inspectionFormSectionFieldModel.id
                    ? inspectionFormSectionFieldModel.id
                    : 0
                "
                field="icon"
                :label="$t('INSPECTION_FORMS.FIELD_ICON')"
                @imageChanged="
                  (file_url) => {
                    inspectionFormSectionFieldModel.icon = file_url;
                  }
                "
              />
              <validation-error :errors="apiValidationErrors.icon" />
            </div>

            <conditional-field-selector
              v-if="!inspectionFormSectionFieldModel.is_copy"
              :model="inspectionFormSectionFieldModel"
              :inspectionForm="inspectionForm"
              @conditionsChanged="
                (model) => {
                  inspectionFormSectionFieldModel = {
                    ...inspectionFormSectionFieldModel,
                    ...model,
                  };
                }
              "
            />

            <div class="inspection-form-group width-1 next-button">
              <base-button
                v-if="!inspectionFormSectionFieldModel.id"
                size="sm"
                @click="addInspectionFormSectionField"
                :disabled="loading"
              >
                {{ $t("INSPECTION_FORMS.ADD_INSPECTION_FORM_SECTION_FIELD") }}
                <span v-if="loading" class="btn-inner--icon">
                  <i class="fas fa-spinner fa-spin"></i>
                </span>
                <i class="far fa-arrow-right"></i>
              </base-button>

              <base-button
                v-if="!!inspectionFormSectionFieldModel.id"
                size="sm"
                @click="editInspectionFormSectionField"
                :disabled="loading"
              >
                {{
                  $t("INSPECTION_FORMS.UPDATE_INSPECTION_FORM_SECTION_FIELD")
                }}
                <span v-if="loading" class="btn-inner--icon">
                  <i class="fas fa-spinner fa-spin"></i>
                </span>
                <i class="far fa-arrow-right"></i>
              </base-button>

              <base-button
                size="sm"
                class="transparent"
                @click="closeInspectionFormSectionFieldModal"
                :disabled="loading"
              >
                {{ $t("COMMON.CLOSE") }}
              </base-button>

              <base-button
                v-if="!!inspectionFormSectionFieldModel.id"
                size="sm"
                @click="deleteInspectionFormSectionField"
                :disabled="loading"
              >
                {{
                  $t("INSPECTION_FORMS.DELETE_INSPECTION_FORM_SECTION_FIELD")
                }}
                <span v-if="loading" class="btn-inner--icon">
                  <i class="fas fa-spinner fa-spin"></i>
                </span>
                <i class="far fa-trash"></i>
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { inspectionFormFieldOptions } from "@/constants/inspectionForms";
import { INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET } from "@/constants/inspectionForms";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ImageSelector from "@/components/ImageSelector.vue";
import InspectionFormSectionFieldSelector from "@/components/InspectionFormSectionFieldSelector.vue";
import defaultInspectionFormSectionField from "./defaultInspectionFormSectionField";
import InspectionFormSectionFieldFormThreshold from "./InspectionFormSectionFieldFormThreshold.vue";
import ConditionalFieldSelector from "../ConditionalFieldSelector.vue";

export default {
  name: "inspection-form-section-field-form",

  components: {
    HtmlEditor,
    ValidationError,
    ImageSelector,
    InspectionFormSectionFieldFormThreshold,
    InspectionFormSectionFieldSelector,
    ConditionalFieldSelector,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin, requestErrorMixin],

  props: [
    "inspectionForm",
    "inspectionFormSection",
    "inspectionFormSectionField",
    "showModal",
    "loading",
    "formErrors",
  ],

  data() {
    return {
      inspectionFormFieldOptions: inspectionFormFieldOptions,
      inspectionFormSectionFieldModel: cloneDeep(
        defaultInspectionFormSectionField
      ),
      INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET:
        INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET,
    };
  },

  computed: {},

  methods: {
    closeInspectionFormSectionFieldModal() {
      this.$emit("onCloseInspectionFormSectionFieldModal", true);
    },

    addInspectionFormSectionField() {
      const inspectionFormSectionFieldData = cloneDeep(
        this.inspectionFormSectionFieldModel
      );
      if (inspectionFormSectionFieldData.copiedField) {
        if (inspectionFormSectionFieldData.copiedField.id == null) {
          inspectionFormSectionFieldData.copiedField = null;
        }
      }
      this.$emit(
        "onAddInspectionFormSectionFieldForm",
        inspectionFormSectionFieldData
      );
    },

    editInspectionFormSectionField() {
      const inspectionFormSectionFieldData = cloneDeep(
        this.inspectionFormSectionFieldModel
      );
      if (inspectionFormSectionFieldData.copiedField) {
        if (inspectionFormSectionFieldData.copiedField.id == null) {
          inspectionFormSectionFieldData.copiedField = null;
        }
      }
      this.$emit(
        "onEditInspectionFormSectionFieldForm",
        inspectionFormSectionFieldData
      );
    },

    deleteInspectionFormSectionField() {
      const inspectionFormSectionFieldData = cloneDeep(
        this.inspectionFormSectionFieldModel
      );
      this.$emit(
        "onDeleteInspectionFormSectionField",
        inspectionFormSectionFieldData
      );
    },
  },

  mounted() {},

  watch: {
    inspectionFormSectionField(inspectionFormSectionField) {
      if (inspectionFormSectionField) {
        this.inspectionFormSectionFieldModel = cloneDeep(
          inspectionFormSectionField
        );
      }
      this.resetApiValidation();
    },
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
