export default {
  type: "inspection-form-sections",
  order_number: 0,
  factor: 1,
  name: null,
  excerpt: "",
  relationshipNames: ["inspectionForm", "parent"],
  inspectionForm: {
    type: "inspection-forms",
    id: null,
  },
  conditional_field: false,
};
