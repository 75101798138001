<template>
  <div class="container-fluid inspection-global">
    <div class="page-title">
      <i class="far fa-list-alt"></i>
      <h1>{{ $t("COMMON.INSPECTION_FORMS") }}</h1>
    </div>

    <div class="list-table">
      <inspection-form-list-table
        @onAddInspectionForm="openInspectionFormCreateModal"
        @onViewInspectionForm="openInspectionFormViewModal"
        @onEditInspectionForm="openInspectionFormEditModal"
        @onDeleteInspectionForm="deleteInspectionForm"
        :key="renderKey * 100"
      />
    </div>

    <transition name="slide-right">
      <div
        v-if="isViewInspectionFormModalOpened"
        class="resizable-wrapper no-line"
        v-bind:class="[isViewInspectionFormModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'INSPECTION_FORM'"
          @onCloseModal="closeInspectionFormModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("INSPECTION_FORMS.VIEW_INSPECTION_FORM") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openInspectionForm"
                :objectType="'inspectionForms'"
                :objectId="openInspectionForm.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <inspection-form-view-sync-fields
                  v-if="openInspectionFormLoaded"
                  :inspectionForm="openInspectionForm"
                  @onInspectionSynched="onInspectionSynched"
                />
                <button
                  class="edit"
                  @click="openInspectionFormEditModal(openInspectionForm)"
                >
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button
                  class="delete"
                  @click="deleteInspectionForm(openInspectionForm)"
                >
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="viewFormDemo(openInspectionForm)"
                >
                  <span>
                    {{ $t("INSPECTION_FORMS.VIEW_DEMO") }}
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="duplicateInspectionForm(openInspectionForm)"
                >
                  <span>
                    {{ $t("INSPECTION_FORMS.DUPLICATE") }}
                  </span>
                </base-button>
              </base-dropdown>

              <button class="expand">
                <i class="fal fa-expand-arrows"></i>
              </button>

              <button class="btn close" @click="closeInspectionFormModal(true)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-inspection-form-component
              v-if="openInspectionForm"
              :inspectionFormId="openInspectionForm.id"
              @inspectionFormLoaded="
                (inspectionForm) => {
                  openInspectionForm = inspectionForm;
                  openInspectionFormLoaded = true;
                }
              "
            />
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide-right">
      <div
        v-if="isEditInspectionFormModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditInspectionFormModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'INSPECTION_FORM'"
          @onCloseModal="closeInspectionFormModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("INSPECTION_FORMS.EDIT_INSPECTION_FORM") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="view"
                  @click="openInspectionFormViewModal(openInspectionForm)"
                >
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="expand" id="expand-button">
                <i class="fal fa-expand-arrows"></i>
              </button>
              <button class="btn close" @click="closeInspectionFormModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-inspection-form-component
              v-if="openInspectionForm"
              :inspectionFormId="openInspectionForm.id"
              @onViewInspectionForm="openInspectionFormViewModal"
            />
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide-right">
      <div
        v-if="isAddInspectionFormModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddInspectionFormModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'INSPECTION_FORM'"
          @onCloseModal="closeInspectionFormModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>{{ $t("INSPECTION_FORMS.ADD_INSPECTION_FORM") }}</h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="expand" id="expand-button">
                <i class="fal fa-expand-arrows"></i>
              </button>
              <button class="close" @click="closeInspectionFormModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-inspection-form-component
              @onViewInspectionForm="openInspectionFormViewModal"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import InspectionFormListTable from "./partials/InspectionFormListTable.vue";
import EditInspectionFormComponent from "./components/EditInspectionFormComponent.vue";
import AddInspectionFormComponent from "./components/AddInspectionFormComponent.vue";
import ViewInspectionFormComponent from "./components/ViewInspectionFormComponent.vue";
import InspectionFormViewSyncFields from "./partials/InspectionFormViewSyncFields.vue";

export default {
  layout: "DashboardLayout",

  components: {
    InspectionFormListTable,
    NotificationSubscription,
    EditInspectionFormComponent,
    AddInspectionFormComponent,
    ViewInspectionFormComponent,
    InspectionFormViewSyncFields,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const inspectionformId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewInspectionFormModalOpened = false;
    let isEditInspectionFormModalOpened = false;
    let isAddInspectionFormModalOpened = false;
    let openInspectionForm = null;
    if (inspectionformId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewInspectionFormModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditInspectionFormModalOpened = true;
      }
      openInspectionForm = { id: inspectionformId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddInspectionFormModalOpened = true;
    }
    return {
      isViewInspectionFormModalOpened: isViewInspectionFormModalOpened,
      isEditInspectionFormModalOpened: isEditInspectionFormModalOpened,
      isAddInspectionFormModalOpened: isAddInspectionFormModalOpened,
      openInspectionForm: openInspectionForm,
      renderKey: 1,
      openInspectionFormLoaded: false,
    };
  },

  methods: {
    openInspectionFormCreateModal() {
      this.closeInspectionFormModal();
      this.isAddInspectionFormModalOpened = true;

      this.$router.replace({
        name: "List InspectionForms",
        query: { action: QUERY_ACTIONS_ADD },
      });
    },

    openInspectionFormViewModal(inspectionForm, reRender = false) {
      this.closeInspectionFormModal();
      this.openInspectionForm = inspectionForm;
      this.isViewInspectionFormModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      this.$router.replace({
        name: "List InspectionForms",
        query: { id: this.openInspectionForm.id, action: QUERY_ACTIONS_VIEW },
      });
    },

    openInspectionFormEditModal(inspectionForm) {
      this.closeInspectionFormModal();
      this.openInspectionForm = inspectionForm;
      this.isEditInspectionFormModalOpened = true;

      this.$router.replace({
        name: "List InspectionForms",
        query: { id: this.openInspectionForm.id, action: QUERY_ACTIONS_EDIT },
      });
    },

    closeInspectionFormModal(goBack = false, grid = false) {
      if (goBack && this.$store.getters["routes/previousPathRoute"]) {
        if (grid) {
          this.$store.dispatch("routes/goBackToPreviousGrid");
        } else {
          this.$store.dispatch("routes/goToPreviousPath");
        }
        return;
      }
      this.isAddInspectionFormModalOpened = false;
      this.isViewInspectionFormModalOpened = false;
      this.isEditInspectionFormModalOpened = false;
      this.openInspectionForm = null;

      if (
        this.$route.name !== "List InspectionForms" ||
        JSON.stringify(this.$route.query) !== JSON.stringify({})
      ) {
        this.$router
          .replace({
            name: "List InspectionForms",
            query: {},
          })
          .catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              throw err;
            }
          });
      }
    },

    async deleteInspectionForm(inspectionForm) {
      const confirmation = await swal.fire({
        title: this.$t("INSPECTION_FORMS.DELETE_THIS_INSPECTION_FORM"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          await this.$store.dispatch(
            "inspectionForms/destroy",
            inspectionForm.id
          );
          this.renderKey++;
          this.closeInspectionFormModal();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("INSPECTION_FORMS.INSPECTION_FORM_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async duplicateInspectionForm(inspectionForm) {
      const confirmation = await swal.fire({
        title: this.$t("INSPECTION_FORMS.DUPLICATE_THIS_INSPECTION_FORM"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          swal.fire({
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
              swal.showLoading();
            },
          });
          await this.$store.dispatch(
            "inspectionForms/duplicate",
            inspectionForm.id
          );
          const newInspectionForm =
            this.$store.getters["inspectionForms/inspectionForm"];
          this.renderKey++;
          this.openInspectionFormEditModal(newInspectionForm, true);
          swal.close();
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewFormDemo(inspectionForm) {
      this.$router.push({
        name: "Demo InspectionForm",
        params: { id: inspectionForm.id },
      });
    },

    onInspectionSynched() {
      this.renderKey++;
    },
  },
};
</script>
