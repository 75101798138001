<template>
  <div class="elite-tabs-wrapper-content">
    <div class="border-bottom">
      <div class="custom-header">
        <base-button
          class="elite-button add"
          icon
          size="sm"
          @click="addNoteContact()"
        >
          <span class="btn-inner--icon">
            <i class="far fa-plus-circle"></i>
          </span>
          <span class="btn-inner--text">
            {{ $t("INSPECTION_FORMS.ADD_INSPECTION_FORM_NOTE") }}
          </span>
        </base-button>
      </div>
    </div>
    <inspection-form-notes-list
      :inspection-form="inspectionForm"
      @deleteNote="deleteNote"
      @editNote="editNote"
    />
    <inspection-form-note-form-modal
      :loading="loading"
      :note-data="note"
      :form-errors="formErrors"
      :show-modal="showNoteFormModal"
      @onCloseModal="onCloseModal"
      @noteFormSubmitted="handleSubmit"
    />
  </div>
</template>

<script>
import InspectionFormNotesList from "./InspectionFormNotes/InspectionFormNotesList.vue";
import InspectionFormNoteFormModal from "./InspectionFormNotes/InspectionFormNoteFormModal.vue";
import swal from "sweetalert2";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  name: "inspection-form-view-notes",

  components: {
    InspectionFormNotesList,
    InspectionFormNoteFormModal,
  },

  mixins: [requestErrorMixin],

  props: ["inspectionForm"],

  data() {
    return {
      showNoteFormModal: false,
      note: null,
      rerenderKey: 0,
      loading: false,
      formErrors: null,
    };
  },

  computed: {},

  created() {},

  methods: {
    addNoteContact() {
      this.showNoteFormModal = true;
    },

    onCloseModal() {
      this.showNoteFormModal = false;
      this.note = null;
      this.$emit("onChangeInspectionForm", true);
      this.rerenderKey++;
    },
    handleSubmit(note) {
      this.loading = true;
      const noteData = {
        ...note,
        inspectionForm: {
          type: "inspection-forms",
          id: this.inspectionForm.id,
        },
      };

      if (noteData.id) {
        this.updateNote(noteData);
      } else {
        this.addNote(noteData);
      }
    },

    async addNote(note) {
      try {
        await this.$store.dispatch("inspectionFormNote/add", note);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTION_FORMS.INSPECTION_FORM_NOTE_ADDED"),
        });
        this.onCloseModal();
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
      } finally {
        this.loading = false;
      }
    },

    async updateNote(note) {
      try {
        await this.$store.dispatch("inspectionFormNote/update", note);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTION_FORMS.INSPECTION_FORM_NOTE_UPDATED"),
        });
        this.onCloseModal();
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
      } finally {
        this.loading = false;
      }
    },

    editNote(note) {
      this.note = { ...note };
      this.showNoteFormModal = true;
    },

    async deleteNote(note) {
      const confirmation = await swal.fire({
        title: this.$t("INSPECTION_FORMS.DELETE_THIS_INSPECTION_FORM_NOTE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          await this.$store.dispatch("inspectionFormNote/destroy", note.id);
          this.renderKey++;
          this.$emit("onChangeInspectionForm", true);
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("INSPECTION_FORMS.INSPECTION_FORM_NOTE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },

  mounted() {},

  watch: {},
};
</script>
