export const MULTIPLIER_TYPE_POINTS = "POINTS";
export const MULTIPLIER_TYPE_REPAIR_VALUE = "REPAIR_VALUE";

export const inspectionFormMultiplierTypesOptions = [
  MULTIPLIER_TYPE_POINTS,
  MULTIPLIER_TYPE_REPAIR_VALUE,
];

export const MULTIPLIER_FIELD_MAKE = "make";
export const MULTIPLIER_FIELD_MODEL_YEAR = "model_year";
export const MULTIPLIER_FIELD_ODOMETER = "odometer";
export const inspectionFormMultiplierFieldsOptions = [
  MULTIPLIER_FIELD_MAKE,
  MULTIPLIER_FIELD_MODEL_YEAR,
  MULTIPLIER_FIELD_ODOMETER,
];

export const MULTIPLIER_OPERATION_EQUALS = "EQUALS";
export const MULTIPLIER_OPERATION_GREATER_THAN = "GREATER_THAN";
export const MULTIPLIER_OPERATION_LESS_THAN = "LESS_THAN";
export const MULTIPLIER_OPERATION_CONTAINS = "CONTAINS";
export const inspectionFormMultiplierOperationsOptions = [
  MULTIPLIER_OPERATION_EQUALS,
  MULTIPLIER_OPERATION_GREATER_THAN,
  MULTIPLIER_OPERATION_LESS_THAN,
  MULTIPLIER_OPERATION_CONTAINS,
];
