import { MULTIPLIER_OPERATION_EQUALS } from "@/constants/inspectionFormMultipliers";

export default {
  type: "inspection-form-multipliers",
  multiplier_type: null,
  field: null,
  value: null,
  multiplier: null,
  operation: MULTIPLIER_OPERATION_EQUALS,
  relationshipNames: ["inspectionForm"],
  inspectionForm: {
    type: "inspection-forms",
    id: null,
  },
};
