<template>
  <div
    v-if="showModal"
    class="resizable-wrapper"
    v-bind:class="[showModal ? 'show' : 'hide']"
  >
    <div class="resizable-wrapper-content">
      <div class="resizable-wrapper-content-header">
        <div class="resizable-wrapper-content-header-left">
          <base-button
            icon
            size="sm"
            class="back-button"
            @click="closeInspectionFormMultiplierModal"
          >
            <i class="fal fa-arrow-left"></i>
          </base-button>
          <h1 v-if="!inspectionFormMultiplier.id">
            {{ $t("INSPECTION_FORMS.NEW_INSPECTION_FORM_MULTIPLIER") }}
          </h1>
          <h1 v-if="!!inspectionFormMultiplier.id">
            {{ $t("INSPECTION_FORMS.EDIT_INSPECTION_FORM_MULTIPLIER") }}
          </h1>
        </div>
        <div class="resizable-wrapper-content-header-right">
          <button class="close" @click="closeInspectionFormMultiplierModal">
            <i class="fal fa-times"></i>
          </button>
        </div>
      </div>
      <div class="resizable-wrapper-content-body">
        <div class="multiplier-form-wrapper">
          <div class="inspection-form">
            <div class="inspection-form-group width-1">
              <base-input
                :label="$t('INSPECTION_FORMS.MULTIPLIER_TYPE')"
                :placeholder="$t('INSPECTION_FORMS.MULTIPLIER_TYPE')"
              >
                <el-select
                  v-model="inspectionFormMultiplierModel.multiplier_type"
                  :placeholder="$t('INSPECTION_FORMS.MULTIPLIER_TYPE')"
                >
                  <el-option
                    v-for="item in inspectionFormMultiplierTypesOptions"
                    :key="item"
                    :label="
                      $t(
                        `INSPECTION_FORMS.MULTIPLIER_TYPE_${item.toUpperCase()}`
                      )
                    "
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </base-input>
              <validation-error :errors="apiValidationErrors.multiplier_type" />
            </div>
            <div class="inspection-form-group width-1">
              <base-input
                :label="$t('INSPECTION_FORMS.MULTIPLIER_FIELD')"
                :placeholder="$t('INSPECTION_FORMS.MULTIPLIER_FIELD')"
              >
                <el-select
                  v-model="inspectionFormMultiplierModel.field"
                  :placeholder="$t('INSPECTION_FORMS.MULTIPLIER_FIELD')"
                >
                  <el-option
                    v-for="item in inspectionFormMultiplierFieldsOptions"
                    :key="item"
                    :label="
                      $t(
                        `INSPECTION_FORMS.MULTIPLIER_FIELD_${item.toUpperCase()}`
                      )
                    "
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </base-input>
              <validation-error :errors="apiValidationErrors.field" />
            </div>

            <div class="inspection-form-group width-1">
              <base-input
                :label="$t('INSPECTION_FORMS.MULTIPLIER_OPERATION')"
                :placeholder="$t('INSPECTION_FORMS.MULTIPLIER_OPERATION')"
              >
                <el-select
                  v-model="inspectionFormMultiplierModel.operation"
                  :placeholder="$t('INSPECTION_FORMS.MULTIPLIER_OPERATION')"
                >
                  <el-option
                    v-for="item in inspectionFormMultiplierOperationsOptions"
                    :key="item"
                    :label="
                      $t(
                        `INSPECTION_FORMS.MULTIPLIER_OPERATION_${item.toUpperCase()}`
                      )
                    "
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </base-input>
              <validation-error :errors="apiValidationErrors.operation" />
            </div>

            <div class="inspection-form-group width-1">
              <base-input
                :label="$t('INSPECTION_FORMS.MULTIPLIER_VALUE')"
                :placeholder="$t('INSPECTION_FORMS.MULTIPLIER_VALUE')"
                v-model="inspectionFormMultiplierModel.value"
              >
              </base-input>
              <validation-error :errors="apiValidationErrors.value" />
            </div>

            <div class="inspection-form-group width-1">
              <base-input
                :label="$t('INSPECTION_FORMS.MULTIPLIER_MULTIPLIER')"
                :placeholder="$t('INSPECTION_FORMS.MULTIPLIER_MULTIPLIER')"
                v-model="inspectionFormMultiplierModel.multiplier"
                input-classes="form-control-alternative"
                type="number"
                step="0.01"
              >
              </base-input>
              <validation-error :errors="apiValidationErrors.multiplier" />
            </div>

            <div class="inspection-form-group width-1 next-button">
              <base-button
                size="sm"
                v-if="!inspectionFormMultiplierModel.id"
                @click="addInspectionFormMultiplier"
              >
                <span v-if="loading" class="btn-inner--icon">
                  <i class="fas fa-spinner fa-spin"></i>
                </span>
                {{ $t("INSPECTION_FORMS.ADD_INSPECTION_FORM_MULTIPLIER") }}
                <i class="far fa-arrow-right"></i>
              </base-button>

              <base-button
                size="sm"
                v-if="inspectionFormMultiplierModel.id"
                @click="editInspectionFormMultiplier"
              >
                <span v-if="loading" class="btn-inner--icon">
                  <i class="fas fa-spinner fa-spin"></i>
                </span>
                {{ $t("INSPECTION_FORMS.UPDATE_INSPECTION_FORM_MULTIPLIER") }}
                <i class="far fa-arrow-right"></i>
              </base-button>

              <base-button
                size="sm"
                class="transparent"
                @click="closeInspectionFormMultiplierModal"
                :disabled="loading"
              >
                {{ $t("COMMON.CLOSE") }}
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";
import {
  inspectionFormMultiplierTypesOptions,
  inspectionFormMultiplierFieldsOptions,
  inspectionFormMultiplierOperationsOptions,
} from "@/constants/inspectionFormMultipliers";
import defaultInspectionFormMultiplier from "./defaultInspectionFormMultiplier";

export default {
  name: "inspection-form-multiplier-form",

  components: {
    ValidationError,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin, requestErrorMixin],

  props: [
    "inspectionForm",
    "inspectionFormMultiplier",
    "showModal",
    "loading",
    "formErrors",
  ],

  data() {
    return {
      inspectionFormMultiplierModel: cloneDeep(defaultInspectionFormMultiplier),
      inspectionFormMultiplierTypesOptions,
      inspectionFormMultiplierFieldsOptions,
      inspectionFormMultiplierOperationsOptions,
    };
  },

  computed: {},

  methods: {
    closeInspectionFormMultiplierModal() {
      this.$emit("onCloseInspectionFormMultiplierModal", true);
    },

    addInspectionFormMultiplier() {
      const inspectionFormMultiplierData = cloneDeep(
        this.inspectionFormMultiplierModel
      );
      this.$emit(
        "onAddInspectionFormMultiplierForm",
        inspectionFormMultiplierData
      );
    },

    editInspectionFormMultiplier() {
      const inspectionFormMultiplierData = cloneDeep(
        this.inspectionFormMultiplierModel
      );
      this.$emit(
        "onEditInspectionFormMultiplierForm",
        inspectionFormMultiplierData
      );
    },
  },

  mounted() {},

  watch: {
    inspectionFormMultiplier(inspectionFormMultiplier) {
      if (inspectionFormMultiplier) {
        this.inspectionFormMultiplierModel = cloneDeep(
          inspectionFormMultiplier
        );
      }
      this.resetApiValidation();
    },
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
