<template>
  <div>
    <slot name="header">
      <h3 v-if="header" class="table-title">{{ header }}</h3>
    </slot>

    <div class="buttons-wrapper margin" v-if="showTabs">
      <base-button
        class="all"
        :class="{
          active:
            selectedStatus == null &&
            selectedInspectionAssignedTo == null &&
            selectedInspectionCreator == null,
        }"
        @click="changeSelectedStatus(null)"
      >
        <span class="text">{{ $t("INSPECTIONS.ALL") }}</span>
        <span class="number" v-if="!isNaN(counters.all)">{{
          counters.all
        }}</span>
      </base-button>
      <base-button
        class="DRAFT"
        :class="{ active: selectedStatus == INSPECTION_STATUS_DRAFT }"
        @click="() => changeSelectedStatus(INSPECTION_STATUS_DRAFT)"
        v-if="
          $currentUserCan(this.$permissions.PERM_CREATE_INSPECTIONS) ||
          $currentUserCan(this.$permissions.PERM_VIEW_ANY_INSPECTIONS)
        "
      >
        <span class="text">{{ $t("COMMON.DRAFTS") }}</span>
        <span class="number" v-if="!isNaN(counters.draft)">{{
          counters.draft
        }}</span>
      </base-button>
      <base-button
        class="PENDING"
        :class="{
          active: selectedStatus == INSPECTION_STATUS_PENDING,
        }"
        @click="() => changeSelectedStatus(INSPECTION_STATUS_PENDING)"
        v-if="
          $currentUserCan(this.$permissions.PERM_APPROVE_INSPECTIONS) ||
          $currentUserCan(this.$permissions.PERM_VIEW_ANY_INSPECTIONS)
        "
      >
        <span class="text">{{ $t("COMMON.PENDING") }}</span>
        <span class="number" v-if="!isNaN(counters.pending)">{{
          counters.pending
        }}</span>
      </base-button>
      <base-button
        class="ASSIGNED"
        :class="{
          active:
            selectedStatus == INSPECTION_STATUS_ASSIGNED &&
            selectedInspectionAssignedTo == null &&
            !selectedAssignedToGroup,
        }"
        @click="() => changeSelectedStatus(INSPECTION_STATUS_ASSIGNED)"
        v-if="
          $currentUserCan(this.$permissions.PERM_START_INSPECTIONS) ||
          $currentUserCan(
            this.$permissions.PERM_VIEW_ASSIGNED_TO_GROUP_INSPECTIONS
          ) ||
          $currentUserCan(this.$permissions.PERM_VIEW_ANY_INSPECTIONS)
        "
      >
        <span class="text">{{ $t("INSPECTIONS.ASSIGNED") }}</span>
        <span class="number" v-if="!isNaN(counters.assigned)">{{
          counters.assigned
        }}</span>
      </base-button>
      <!-- <base-button
        class="IN_PROGRESS"
        :class="{
          active:
            selectedStatus == INSPECTION_STATUS_IN_PROGRESS &&
            selectedInspectionAssignedTo != me.id,
        }"
        @click="() => changeSelectedStatus(INSPECTION_STATUS_IN_PROGRESS)"
        v-if="
          $currentUserCan(this.$permissions.PERM_START_INSPECTIONS) ||
          $currentUserCan(
            this.$permissions.PERM_VIEW_ASSIGNED_TO_GROUP_INSPECTIONS
          ) ||
          $currentUserCan(this.$permissions.PERM_VIEW_ANY_INSPECTIONS)
        "
      >
        <span class="text">{{ $t("COMMON.IN_PROGRESS") }}</span>
        <span class="number" v-if="!isNaN(counters.inProgress)">
          {{ counters.inProgress }}
        </span>
      </base-button> -->
      <base-button
        class="COMPLETED"
        :class="{
          active: selectedStatus == INSPECTION_STATUS_COMPLETED,
        }"
        @click="changeSelectedStatus(INSPECTION_STATUS_COMPLETED)"
        v-if="
          $currentUserCan(this.$permissions.PERM_START_INSPECTIONS) ||
          $currentUserCan(
            this.$permissions.PERM_VIEW_ASSIGNED_TO_GROUP_INSPECTIONS
          ) ||
          $currentUserCan(this.$permissions.PERM_VIEW_ANY_INSPECTIONS)
        "
      >
        <span class="text">{{ $t("INSPECTIONS.COMPLETED") }}</span>
        <span class="number" v-if="!isNaN(counters.completed)">
          {{ counters.completed }}
        </span>
      </base-button>
      <base-button
        class="CANCELED"
        :class="{
          active: selectedStatus == INSPECTION_STATUS_CANCELED,
        }"
        @click="changeSelectedStatus(INSPECTION_STATUS_CANCELED)"
        v-if="$currentUserCan(this.$permissions.PERM_VIEW_ANY_INSPECTIONS)"
      >
        <span class="text">{{ $t("INSPECTIONS.CANCELED") }}</span>
        <span class="number" v-if="!isNaN(counters.canceled)">
          {{ counters.canceled }}
        </span>
      </base-button>
      <!-- <base-button
        class="assign-to-me"
        :class="{
          active:
            selectedStatus == INSPECTION_STATUS_ASSIGNED &&
            selectedInspectionAssignedTo == me.id,
        }"
        @click="changeSelectedStatus(INSPECTION_STATUS_ASSIGNED, me.id)"
        v-if="
          $currentUserCan(this.$permissions.PERM_START_INSPECTIONS) ||
          $currentUserCan(this.$permissions.PERM_VIEW_ANY_INSPECTIONS)
        "
      >
        <span class="text">{{ $t("INSPECTIONS.ASSIGNED_TO_ME") }}</span>
        <span class="number" v-if="!isNaN(counters.assignedToMe)">
          {{ counters.assignedToMe }}
        </span>
      </base-button> -->
      <base-button
        class="to-do"
        :class="{
          active:
            selectedStatus == INSPECTION_STATUS_ASSIGNED &&
            selectedAssignedToGroup,
        }"
        @click="
          changeSelectedStatus(INSPECTION_STATUS_ASSIGNED, null, null, true)
        "
        v-if="
          $currentUserCan(
            this.$permissions.PERM_VIEW_ASSIGNED_TO_GROUP_INSPECTIONS
          ) || $currentUserCan(this.$permissions.PERM_VIEW_ANY_INSPECTIONS)
        "
      >
        <span class="text">{{ $t("COMMON.TODO") }}</span>
        <span class="number" v-if="!isNaN(counters.todo)">
          {{ counters.todo }}
        </span>
      </base-button>
      <!-- <base-button
        class="by-me"
        :class="{
          active: selectedInspectionCreator == me.id,
        }"
        @click="changeSelectedStatus(null, null, me.id)"
        v-if="
          $currentUserCan(this.$permissions.PERM_CREATE_INSPECTIONS) ||
          $currentUserCan(this.$permissions.PERM_VIEW_ANY_INSPECTIONS)
        "
      >
        <span class="text">{{ $t("INSPECTIONS.CREATED_BY_ME") }}</span>
        <span class="number" v-if="!isNaN(counters.createdByMe)">
          {{ counters.createdByMe }}
        </span>
      </base-button> -->
    </div>

    <div class="list-table-inner-body">
      <div class="list-table-inner-body-inner">
        <el-table
          class="align-items-center table-flush inspections"
          header-row-class-name="thead-light has-image"
          :data="inspections"
          @sort-change="sortChange"
          row-class-name="cursor-pointer"
          @row-click="
            (row) => {
              viewInspection(row);
            }
          "
        >
          <div class="table-loading" slot="empty" v-show="loading">
            <img src="/img/loading.gif" />
          </div>

          <el-table-column width="75" cell-class-name="pic">
            <template v-slot="{ row }">
              <div class="image" v-if="row.vehicle">
                <img
                  v-if="row.vehicle?.gallery?.length > 0"
                  :src="row.vehicle?.gallery[0]"
                  class="avatar rounded-circle mr-3"
                />
                <span class="default-icon">
                  <i class="far fa-camera"></i>
                </span>
              </div>
            </template>
          </el-table-column>

          <el-table-column :label="$t('COMMON.VEHICLE')" min-width="250">
            <template v-slot="{ row }">
              <div class="vehicle">
                <ul>
                  <li>{{ row?.vehicle?.make }} {{ row?.vehicle?.model }}</li>
                  <li>{{ row?.vehicle?.model_year }}</li>
                </ul>
                <ul>
                  <li>{{ row?.vehicle?.color }}</li>
                  <li class="line" v-if="row.color">|</li>
                  <li>
                    {{ row?.vehicle?.odometer ?? 0 }}
                    {{ row?.vehicle?.odometer_unit }}
                  </li>
                </ul>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('VEHICLES.SKU') + ' / ' + $t('VEHICLES.VIN')"
            prop="sku"
            min-width="220"
          >
            <template v-slot="{ row }">
              <div class="stock">
                <ul>
                  <li v-if="row.vehicle?.sku">{{ row?.vehicle?.sku }}</li>
                  <li><copy-element :element="row.vehicle?.vin" /></li>
                </ul>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('REPAIRS.CLIENT_WORK_ORDER')"
            min-width="200"
          >
            <template v-slot="{ row }">
              <div class="client">
                <ul v-if="!!row.customer">
                  <li>
                    {{ row.customer?.firstname }} {{ row.customer?.lastname }}
                  </li>
                  <li>{{ row.customer?.phone }}</li>
                </ul>
                <ul v-if="!!row.approver">
                  <li>
                    {{ row.approver?.firstname }} {{ row.approver?.lastname }}
                  </li>
                  <li>{{ row.approver?.phone }}</li>
                </ul>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('INSPECTIONS.CODE')"
            prop="code"
            min-width="200"
            sortable="custom"
          >
            <template v-slot="{ row }">
              <div class="client">
                <ul>
                  <li>{{ row.code }}</li>
                  <li><object-link :object="row.inspectionForm" /></li>
                </ul>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.STATUS')"
            prop="status"
            min-width="180"
          >
            <template v-slot="{ row }">
              <inspection-status-badge :inspection="row" />
            </template>
          </el-table-column>

          <el-table-column :label="$t('INSPECTIONS.MECHANICS')" min-width="220">
            <template v-slot="{ row }">
              <users :users="row.assignedToUsers" />
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('INSPECTIONS.OFFICIAL_MECHANIC')"
            min-width="250"
          >
            <template v-slot="{ row }">
              <object-link
                :object="row.completedBy"
                v-if="$currentUserCan($permissions.PERM_VIEW_USERS)"
              />
              <span v-else>{{ $objectDenomination(row.completedBy) }}</span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('INSPECTIONS.UPDATED_AT')"
            prop="updated_at"
            sortable="custom"
            min-width="240"
          >
            <template v-slot="{ row }">
              {{ $timeZoneDateFormat(row.updated_at, "L LT") }}
            </template>
          </el-table-column>

          <el-table-column
            :fixed="$listActionsButtonsPosition()"
            min-width="120"
          >
            <template v-slot="{ row }">
              <div class="table-actions">
                <el-tooltip
                  :content="$t('INSPECTIONS.GENERATE_REPORT')"
                  placement="top"
                  :class="{
                    disabled: !canGenerateReport(row),
                  }"
                >
                  <a
                    type="text"
                    class="table-action view"
                    data-toggle="tooltip"
                    @click.stop="downloadReport(row)"
                  >
                    <i class="fal fa-file-pdf"></i>
                  </a>
                </el-tooltip>
                <el-tooltip :content="$t('COMMON.VIEW')" placement="top">
                  <a
                    type="text"
                    class="table-action view"
                    data-toggle="tooltip"
                    @click.stop="viewInspection(row)"
                  >
                    <i
                      class="fa-light fa-arrow-up-right-and-arrow-down-left-from-center"
                    ></i>
                  </a>
                </el-tooltip>

                <el-tooltip
                  :content="$t('COMMON.SHARE')"
                  placement="top"
                  v-if="canShareInspection(row)"
                >
                  <a
                    type="text"
                    class="table-action delete"
                    data-toggle="tooltip"
                    @click.stop="shareInspection(row)"
                  >
                    <i class="fal fa-share"></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="$t('COMMON.DELETE')"
                  placement="top"
                  :class="{
                    disabled: !canDeleteInspection(row),
                  }"
                  v-else
                >
                  <a
                    type="text"
                    class="table-action delete"
                    data-toggle="tooltip"
                    @click.stop="deleteInspection(row)"
                  >
                    <i class="fa-light fa-trash-can"></i>
                  </a>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div
      class="list-table-inner-footer"
      v-if="showPagination || total > pagination.perPage"
    >
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}
        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import _, { cloneDeep } from "lodash";
import CopyElement from "@/components/CopyElement.vue";
import Users from "@/components/Users.vue";
import InspectionStatusBadge from "./InspectionStatusBadge.vue";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { BasePagination } from "@/components";
import {
  INSPECTION_STATUS_DRAFT,
  INSPECTION_STATUS_APPROVED,
  INSPECTION_STATUS_ASSIGNED,
  INSPECTION_STATUS_COMPLETED,
  INSPECTION_STATUS_CANCELED,
  INSPECTION_STATUS_PENDING,
  INSPECTION_STATUS_IN_PROGRESS,
} from "@/constants/inspections";
import swal from "sweetalert2";
import inspectionsService from "../../../../../store/services/inspections-service";

export default {
  name: "inspection-table",

  components: {
    BasePagination,
    Users,
    CopyElement,
    InspectionStatusBadge,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [],

  props: {
    header: {
      type: String | Boolean | null | undefined,
      default: null,
    },
    showTabs: {
      type: Boolean,
      default: false,
    },
    // pagination: {
    //   type: Object,
    //   default: () => ({
    //     perPage: 20,
    //     currentPage: 1,
    //     perPageOptions: [20, 50, 100, 500],
    //   }),
    // },
    showPagination: {
      type: Boolean,
      default: true,
    },
    filterStatus: {
      type: String,
      default: null,
      description: "Status",
    },
    selectedDateRange: {
      type: Array | String | Date | null,
      default: null,
    },
    excludeStatus: {
      type: String,
      default: null,
    },
    selectedCompletedBy: {
      type: String,
      default: null,
    },
    selectedCreator: {
      type: String,
      default: null,
    },
    selectedCustomer: {
      type: String,
      default: null,
    },
    filterVehicle: {
      type: String,
      default: null,
      description: "Vehicle id",
    },
    selectedVehicleModelYear: {
      type: String | Number,
      default: null,
    },
    selectedVehicleMake: {
      type: String,
      default: null,
    },
    selectedVehicleModel: {
      type: String,
      default: null,
    },
    selectedVehicleSeries: {
      type: String,
      default: null,
    },
    selectedOrganization: {
      type: String,
      default: null,
    },
    selectedInspectionForm: {
      type: String,
      default: null,
    },
    selectedLocations: {
      type: String,
      default: null,
    },
    query: {
      type: String,
      default: null,
    },
  },

  data() {
    const selectedStatus = this.filterStatus;
    return {
      selectedRows: [],
      sort: "-updated_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      inspections: [],
      loading: true,
      selectedStatus,
      selectedTags: [],
      // selectedStatus: null,
      selectedAssignedToGroup: false,
      selectedInspectionAssignedTo: null,
      selectedInspectionCreator: null,
      INSPECTION_STATUS_DRAFT,
      INSPECTION_STATUS_APPROVED,
      INSPECTION_STATUS_ASSIGNED,
      INSPECTION_STATUS_COMPLETED,
      INSPECTION_STATUS_CANCELED,
      INSPECTION_STATUS_PENDING,
      INSPECTION_STATUS_IN_PROGRESS,
      counters: {
        createdByMe: 0,
        todo: 0,
        all: 0,
        draft: 0,
        pending: 0,
        assigned: 0,
        inProgress: 0,
        completed: 0,
        canceled: 0,
        assignedToMe: 0,
      },
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    params() {
      let params = {
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.query ? { search: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
        include:
          "organization,creator,customer,allowedLocations,tags,inspectionForm,vehicle,completedBy,assignedToUsers",
      };

      if (this.selectedStatus) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            status: this.selectedStatus,
          },
        };
      }

      if (this.filterOrganization) {
        params = {
          ...params,
          filter: { ...params.filter, organization: this.filterOrganization },
        };
      }

      if (this.selectedOrganization) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            organization: this.selectedOrganization,
          },
        };
      }

      if (this.filterCustomer) {
        params = {
          ...params,
          filter: { ...params.filter, customer: this.filterCustomer },
        };
      }
      if (this.selectedCustomer) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            organization: this.selectedCustomer,
          },
        };
      }

      if (this.filterInspectionForm) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            inspectionForm: this.filterInspectionForm,
          },
        };
      }
      if (this.selectedInspectionForm) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            inspectionForm: this.selectedInspectionForm,
          },
        };
      }
      if (this.selectedLocations) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            allowedLocations: this.selectedLocations.map((loc) => loc.id),
          },
        };
      }
      if (this.selectedTags) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            tags: this.selectedTags.map((item) => item.id),
          },
        };
      }

      if (this.selectedDateRange) {
        if (this.selectedDateRange.length == 2) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              created_at: [
                this.selectedDateRange[0] + " 00:00:00",
                this.selectedDateRange[1] + " 23:59:59",
              ],
            },
          };
        }
      }

      if (this.selectedStatus) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            status: this.selectedStatus,
          },
        };
      }

      if (this.filterAssignedTo) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            assignedToUsers: this.filterAssignedTo,
          },
        };
      }

      if (this.filterAssignedToGroup) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            assignedToRoles: this.filterAssignedToGroup,
          },
        };
      }

      if (this.selectedAssignedTo) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            assignedToUsers: this.selectedAssignedTo,
          },
        };
      }

      if (this.selectedAssignedBy) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            assignedBy: this.selectedAssignedBy,
          },
        };
      }

      if (this.selectedAssignedToGroup) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            assignedToRoles: this.selectedAssignedToGroup,
          },
        };
      }

      if (this.selectedCanceledBy) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            canceledBy: this.selectedCanceledBy,
          },
        };
      }

      if (this.selectedCompletedBy) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            completedBy: this.selectedCompletedBy,
          },
        };
      }

      if (this.filterCreator) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            creator: this.filterCreator,
          },
        };
      }

      if (this.selectedCreator) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            creator: this.selectedCreator,
          },
        };
      }

      if (this.filterVehicle) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            vehicle: this.filterVehicle,
          },
        };
      }

      if (this.selectedVehicleModelYear) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            vehicle: {
              ...params.filter?.vehicle,
              model_year: this.selectedVehicleModelYear,
            },
          },
        };
      }

      if (this.selectedVehicleMake) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            vehicle: {
              ...params.filter?.vehicle,
              make: this.selectedVehicleMake,
            },
          },
        };
      }

      if (this.selectedVehicleModel) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            vehicle: {
              ...params.filter?.vehicle,
              model: this.selectedVehicleModel,
            },
          },
        };
      }

      if (this.selectedVehicleSeries) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            vehicle: {
              ...params.filter?.vehicle,
              series: this.selectedVehicleSeries,
            },
          },
        };
      }

      if (this.excludeStatus) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            excludeStatus: this.excludeStatus,
          },
        };
      }

      return params;
    },
  },

  async created() {
    await this.getList();

    if (this.showTabs) {
      this.getCounters();
    }
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      this.inspections = [];
      this.loading = true;

      try {
        let params = this.params;

        await this.$store.dispatch("inspections/list", params);
        this.inspections = this.$store.getters["inspections/list"];
        this.total = this.$store.getters["inspections/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    getCounters() {
      Promise.all([
        inspectionsService.list(this.getParams(), this.$axios),
        inspectionsService.list(
          this.getParams(INSPECTION_STATUS_ASSIGNED, null, null, true),
          this.$axios
        ),
        inspectionsService.list(
          this.getParams(INSPECTION_STATUS_DRAFT),
          this.$axios
        ),
        inspectionsService.list(
          this.getParams(INSPECTION_STATUS_PENDING),
          this.$axios
        ),
        inspectionsService.list(
          this.getParams(INSPECTION_STATUS_CANCELED),
          this.$axios
        ),
        inspectionsService.list(
          this.getParams(INSPECTION_STATUS_ASSIGNED),
          this.$axios
        ),
        inspectionsService.list(
          this.getParams(INSPECTION_STATUS_COMPLETED),
          this.$axios
        ),
        inspectionsService.list(
          this.getParams(INSPECTION_STATUS_IN_PROGRESS),
          this.$axios
        ),
        // inspectionsService.list(
        //   this.getParams(null, null, this.me.id),
        //   this.$axios
        // ),
        // inspectionsService.list(
        //   this.getParams(INSPECTION_STATUS_ASSIGNED, this.me.id),
        //   this.$axios
        // ),
      ]).then((values) => {
        const [
          all,
          todo,
          draft,
          pending,
          canceled,
          assigned,
          completed,
          inProgress,
          // createdByMe,
          // assignedToMe,
        ] = values;
        this.counters = {
          ...this.counters,
          all: all.meta.page.total,
          todo: todo.meta.page.total,
          draft: draft.meta.page.total,
          pending: pending.meta.page.total,
          canceled: canceled.meta.page.total,
          assigned: assigned.meta.page.total,
          completed: completed.meta.page.total,
          inProgress: inProgress.meta.page.total,
          // createdByMe: createdByMe.meta.page.total,
          // assignedToMe: assignedToMe.meta.page.total,
        };
      });
    },

    getParams(status = null, assignedTo = null, creator = null, group = false) {
      let params = {
        filter: cloneDeep(this.params.filter),
        page: {
          number: 1,
          size: 1,
        },
        include: "",
      };

      if (assignedTo) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            assignedToUsers: assignedTo,
          },
        };
      }
      if (group) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            assignedToRoles: group,
          },
        };
      }
      if (creator) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            creator: creator,
          },
        };
      }
      if (status) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            status: status,
          },
        };
      } else {
        delete params.filter.status;
      }

      return params;
    },

    changeSelectedStatus(
      status,
      assignedTo = null,
      creator = null,
      group = false
    ) {
      this.selectedStatus = status;
      // this.selectedInspectionAssignedTo = assignedTo;
      // this.selectedInspectionCreator = creator;
      this.selectedAssignedToGroup = group;
    },

    shareInspection(inspection) {
      this.$emit("onShareInspection", inspection);
    },

    async deleteInspection(inspection) {
      if (!this.canDeleteInspection(inspection)) {
        return;
      }
      const confirmation = await swal.fire({
        title: this.$t("INSPECTIONS.DELETE_THIS_INSPECTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          swal.showLoading();
          await this.$store.dispatch("inspections/destroy", inspection.id);
          await this.getList();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("INSPECTIONS.INSPECTION_DELETED"),
          });
        }
        swal.close();
        await this.getList();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewInspection(inspection) {
      this.$emit("onViewInspection", inspection);
    },

    editInspection(inspection) {
      if (!this.canEditInspection(inspection)) {
        return;
      }
      this.$emit("onEditInspection", inspection);
    },

    addInspection() {
      this.$emit("onAddInspection");
    },

    canEditInspection(inspection) {
      return (
        !!inspection &&
        ![INSPECTION_STATUS_CANCELED, INSPECTION_STATUS_COMPLETED].includes(
          inspection.status
        ) &&
        (this.$currentUserCan(this.$permissions.PERM_EDIT_INSPECTIONS) ||
          this.$currentUserCan(this.$permissions.PERM_APPROVE_INSPECTIONS) ||
          this.$currentUserCan(this.$permissions.PERM_ASSIGN_INSPECTIONS))
      );
    },

    canDeleteInspection(inspection) {
      return (
        !!inspection &&
        inspection.status == INSPECTION_STATUS_DRAFT &&
        this.$currentUserCan(this.$permissions.PERM_DELETE_INSPECTIONS)
      );
    },

    canShareInspection(inspection) {
      return (
        !!inspection &&
        !!inspection.share_key &&
        inspection.status == INSPECTION_STATUS_COMPLETED
      );
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },

    canGenerateReport(inspection) {
      return (
        this.$currentUserCan(this.$permissions.PERM_VIEW_INSPECTIONS) &&
        !!inspection &&
        inspection.status == INSPECTION_STATUS_COMPLETED
      );
    },

    async downloadReport(inspection) {
      if (!this.canGenerateReport(inspection)) {
        return;
      }

      if (!inspection) {
        return;
      }

      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const response = await this.$store.dispatch(
          "inspections/getReport",
          inspection.id
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${inspection.code}-report.pdf`);
        document.body.appendChild(link);
        link.click();
        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },
  },

  watch: {
    query: {
      handler: "getList",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getList",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getList",
      immediate: true,
    },
    filterCustomer: {
      handler: "getList",
      immediate: true,
    },
    selectedCustomer: {
      handler: "getList",
      immediate: true,
    },
    filterInspectionForm: {
      handler: "getList",
      immediate: true,
    },
    selectedInspectionForm: {
      handler: "getList",
      immediate: true,
    },
    selectedLocations: {
      handler: "getList",
      immediate: true,
    },
    selectedTags: {
      handler: "getList",
      immediate: true,
    },
    selectedStatus: {
      handler: "getList",
      immediate: true,
    },
    selectedDateRange: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterAssignedTo: {
      handler: "getList",
      immediate: true,
    },
    selectedAssignedTo: {
      handler: "getList",
      immediate: true,
    },
    selectedAssignedBy: {
      handler: "getList",
      immediate: true,
    },
    selectedAssignedToGroup: {
      handler: "getList",
      immediate: true,
    },
    selectedCanceledBy: {
      handler: "getList",
      immediate: true,
    },
    selectedCompletedBy: {
      handler: "getList",
      immediate: true,
    },
    filterAssignedToGroup: {
      handler: "getList",
      immediate: true,
    },
    filterCreator: {
      handler: "getList",
      immediate: true,
    },
    selectedCreator: {
      handler: "getList",
      immediate: true,
    },
    filterVehicle: {
      handler: "getList",
      immediate: true,
    },
    selectedVehicleModelYear: {
      handler: "getList",
      immediate: true,
    },
    selectedVehicleMake: {
      handler: "getList",
      immediate: true,
    },
    selectedVehicleModel: {
      handler: "getList",
      immediate: true,
    },
    selectedVehicleSeries: {
      handler: "getList",
      immediate: true,
    },
    params: {
      handler: function (value, oldValue) {
        if (this.showTabs && value.filter.status == oldValue.filter.status) {
          this.getCounters();
        }
      },
      immediate: false,
      deep: true,
    },

    filterStatus: function (value) {
      this.selectedStatus = value;
    },
  },
};
</script>
