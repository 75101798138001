<template>
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <form class="row" @submit.prevent="handleSubmit">
      <div class="mt-2 col-12">
        <base-input
          :label="`${$t(
            'INSPECTION_FORMS.INSPECTION_FORM_REMINDER_ORDER'
          )} (*)`"
          :placeholder="$t('INSPECTION_FORMS.INSPECTION_FORM_REMINDER_ORDER')"
          v-model="reminder.order"
          input-classes="form-control-alternative"
          type="number"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.order" />
      </div>

      <div class="form-wrapper full mt-2 col-12">
        <base-input
          type="textarea"
          :label="`${$t('COMMON.REMINDER')} (*)`"
          :placeholder="$t('COMMON.REMINDER')"
        >
          <html-editor v-model="reminder.excerpt" @change="onFormChanged()">
          </html-editor>
        </base-input>
        <validation-error :errors="apiValidationErrors.excerpt" />
      </div>
    </form>
    <template slot="footer">
      <button
        type="submit"
        class="btn btn-primary"
        @click="handleSubmit"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        <span v-if="!reminderData || !reminderData.id">
          {{ $t("COMMON.ADD") }}
        </span>
        <span v-else>
          {{ $t("COMMON.UPDATE") }}
        </span>
      </button>
      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closeModal"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import defaultReminder from "./defaultInspectionFormReminders.js";
import HtmlEditor from "@/components/Inputs/HtmlEditor";

export default {
  name: "inspection-form-reminder-form-modal",

  components: { ValidationError, HtmlEditor },

  mixins: [formMixin],

  props: ["reminderData", "showModal", "loading"],

  data() {
    const reminder = this.reminderData
      ? cloneDeep(this.reminderData)
      : cloneDeep(defaultReminder);

    const exampleReminder = { ...defaultReminder };
    return {
      reminder,
      exampleReminder,
      formErrors: null,
    };
  },

  computed: {},

  created() {},

  methods: {
    closeModal() {
      this.$emit("onCloseModal");
      this.reminder = cloneDeep(defaultReminder);
    },
    handleSubmit() {
      this.$emit("reminderFormSubmitted", this.reminder);
    },
  },

  mounted() {},

  watch: {
    showModal(showModal) {
      if (!showModal) {
        this.formErrors = null;
        this.loading = false;
        this.reminder = cloneDeep(defaultReminder);
      }
    },
    reminderData() {
      if (this.reminderData) {
        this.reminder = cloneDeep(this.reminderData);
      }
    },
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
