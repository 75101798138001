<template>
  <el-select
    v-model="fieldModel"
    @change="fieldChanged"
    autocomplete="new-password"
    :placeholder="$t('INSPECTION_FORMS.FIELD')"
    :filterable="filterable"
    :disabled="disabled"
    remote
    :remote-method="getFields"
    :loading="loading"
  >
    <el-option
      v-if="showAll"
      :value="null"
      :label="$t('INSPECTION_FORMS.ALL_FIELDS')"
    >
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="field in fields"
      :key="field.id"
      :value="field.id"
      :label="`${field.inspectionFormSection.name} - ${field.name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "inspection-form-section-field-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    field: {
      type: String,
      default: null,
      description: "Field id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterInspectionForm: {
      type: String,
      default: null,
      description: "Inspection form id",
    },
    filterInspectionFormSection: {
      type: String,
      default: null,
      description: "Inspection form id",
    },
    filterIsCopy: {
      type: Boolean,
      default: true,
      description: "Filter is_copy fields",
    },
  },

  data() {
    return {
      fieldModel: this.field,
      fields: {},
    };
  },

  setup() {},

  created() {
    this.getFields(null, this.field);
  },

  methods: {
    async getFields(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { id: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
          include: "inspectionFormSection",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }
        if (this.filterInspectionForm) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              inspectionForm: this.filterInspectionForm,
            },
          };
        }
        if (this.filterInspectionFormSection) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              inspectionFormSection: this.filterInspectionFormSection,
            },
          };
        }
        if (this.filterIsCopy) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              is_copy: 0,
            },
          };
        }

        await this.$store.dispatch("inspectionFormSectionFields/list", params);
        const fieldsArr = await this.$store.getters[
          "inspectionFormSectionFields/list"
        ];
        this.fields = {};
        fieldsArr.forEach((field) => {
          this.fields[field.id] = field;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    fieldChanged(field) {
      this.$emit("fieldChanged", field);
    },
  },

  watch: {
    field(field) {
      if (field) {
        this.fieldModel = field;
      } else {
        this.fieldModel = null;
        this.getFields(null);
      }
    },
    filterOrganization(filterOrganization) {
      this.getFields();
    },
  },
};
</script>
