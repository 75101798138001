export default {
  type: "inspection-form-section-fields",
  order_number: 0,
  field_type: null,
  name: null,
  excerpt: "",
  points_threshold: [],
  can_upload_image: false,
  can_add_comment: false,
  enable_na: false,
  is_copy: false,
  relationshipNames: ["inspectionFormSection", "copiedField"],
  inspectionFormSection: {
    type: "inspection-form-sections",
    id: null,
  },
  copiedField: {
    type: "inspection-form-section-fields",
    id: null,
  },
  conditional_field: false,
};
