<template>
  <div class="tab-pane-inner">
    <div class="list-table-inner-body">
      <div class="list-table-inner-body-inner">
        <el-table
          class="align-items-center table-flush inspections-forms"
          header-row-class-name="thead-light"
          :data="notes"
        >
          <el-table-column
            :label="$t('INSPECTION_FORMS.INSPECTION_FORM_NOTE_MIN_VALUE')"
            prop="min_value"
            min-width="220"
          >
            <template v-slot="{ row }">
              <span>{{ row.min_value }}</span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('INSPECTION_FORMS.INSPECTION_FORM_NOTE_MAX_VALUE')"
            prop="max_value"
            min-width="220"
          >
            <template v-slot="{ row }">
              <span>{{ row.max_value ?? "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('INSPECTION_FORMS.INSPECTION_FORM_NOTE')"
            prop="note"
            min-width="220"
          >
            <template v-slot="{ row }">
              <span>{{ row.note }}%</span>
            </template>
          </el-table-column>
          <el-table-column
            :fixed="$listActionsButtonsPosition()"
            min-width="120"
          >
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip :content="$t('COMMON.EDIT')" placement="top">
                <a
                  class="table-action"
                  data-toggle="tooltip"
                  type="text"
                  @click="editNote(row)"
                >
                  <i class="fal fa-edit"></i>
                </a>
              </el-tooltip>

              <el-tooltip :content="$t('COMMON.DELETE')" placement="top">
                <a
                  type="text"
                  class="table-action table-action-delete"
                  @click="deleteNote(row)"
                  data-toggle="tooltip"
                >
                  <i class="fal fa-trash-alt"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";

export default {
  name: "inspection-form-notes-list",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  props: ["inspectionForm"],

  data() {
    return {};
  },

  computed: {
    notes: function () {
      return this.inspectionForm.notes.sort(
        (note1, note2) => note2.note - note1.note
      );
    },
  },

  methods: {
    deleteNote(note) {
      this.$emit("deleteNote", note);
    },
    editNote(note) {
      this.$emit("editNote", note);
    },
  },

  mounted() {},

  watch: {
    inspectionForm(inspectionForm) {},
  },
};
</script>
