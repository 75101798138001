<template>
  <div>
    <inspection-form-section-list
      :inspectionForm="inspectionForm"
      @onChangeInspectionForm="inspectionFormChanged"
    />
  </div>
</template>

<script>
import { Button, Tooltip } from "element-ui";
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import InspectionFormSectionList from "./InspectionFormSection/InspectionFormSectionList.vue";
import defaultInspectionFormSection from "./InspectionFormSection/defaultInspectionFormSection";

export default {
  name: "inspection-form-view-form",

  components: {
    InspectionFormSectionList,
    [Tooltip.name]: Tooltip,
    [Button.name]: Button,
  },

  mixins: [formMixin],

  props: ["inspectionForm"],

  data() {
    return {};
  },

  computed: {},

  methods: {
    inspectionFormChanged() {
      this.$emit("onChangeInspectionForm", true);
    },
  },

  mounted() {},

  watch: {},
};
</script>
