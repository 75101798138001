<template>
  <div class="form-group" v-if="havePoints()">
    <label class="form-control-label">
      {{ $t("INSPECTION_FORMS.FIELD_POINTS_THRESHOLD") }}
    </label>
    <div class="text-muted">
      {{ $t(`INSPECTION_FORMS.FIELD_POINTS_INSTRUCTIONS_${fieldType}`) }}
    </div>
    <div
      v-for="(option, key) in thresholdModel"
      :key="key"
      class="options-points"
    >
      <div class="options-points-item">
        <base-input
          :label="$t('INSPECTION_FORMS.FIELD_POINTS_THRESHOLD_OPTION')"
          :placeholder="$t('INSPECTION_FORMS.FIELD_POINTS_THRESHOLD_OPTION')"
          v-model="option.option"
          @change="optionsChanged"
          :disabled="!canChangeThresholdOption()"
        />
      </div>
      <div class="options-points-item">
        <base-input
          :label="$t('INSPECTION_FORMS.FIELD_POINTS_THRESHOLD_POINTS')"
          :placeholder="$t('INSPECTION_FORMS.FIELD_POINTS_THRESHOLD_POINTS')"
          v-model="option.points"
          @change="optionsChanged"
        />
      </div>
      <div class="item-delete" v-if="canRemoveThresholdPoints()">
        <label class="form-control-label mb-4"></label>
        <base-button
          type="button"
          class="form-control btn btn-sm col-12 btn-danger"
          @click.prevent="() => removeElement(key)"
        >
          <i class="fal fa-times"></i>
        </base-button>
      </div>
    </div>
    <base-button
      type="button"
      class="btn add-points"
      @click.prevent="addElement"
      v-if="canAddThresholdPoints()"
    >
      <i class="fal fa-plus"></i>
    </base-button>
  </div>
</template>

<script>
import { Option, Select } from "element-ui";
import { cloneDeep } from "lodash";
import {
  INSPECTION_FORM_FIELD_TYPE_CHECK,
  INSPECTION_FORM_FIELD_TYPE_TEXT,
  INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET,
  INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET,
} from "@/constants/inspectionForms";
import BaseInput from "@/components/Inputs/BaseInput.vue";

export default {
  name: "inspection-form-section-field-form-threshold",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseInput,
  },

  props: {
    thresholdData: {
      default: () => [],
      description: "threshold Data array",
    },
    fieldType: {
      type: String,
      default: null,
      description: "Field type",
    },
    enableNa: {
      type: Boolean,
      default: false,
      description: "Enable NA",
    },
  },

  data() {
    return {
      thresholdModel: this.getThresholdModelData(),
    };
  },

  setup() {},

  created() {},

  methods: {
    addElement() {
      this.thresholdModel.push({ option: null, points: 0 });
      this.optionsChanged();
    },

    removeElement(key) {
      this.thresholdModel = this.thresholdModel.filter((_, idx) => idx !== key);
      this.optionsChanged();
    },

    optionsChanged() {
      let thresholdData = cloneDeep(this.thresholdModel);
      this.$emit("onThresholdChanged", thresholdData);
    },

    havePoints() {
      if (this.fieldType === INSPECTION_FORM_FIELD_TYPE_TEXT) {
        return false;
      }
      return true;
    },

    canChangeThresholdOption() {
      if (
        this.fieldType === INSPECTION_FORM_FIELD_TYPE_CHECK ||
        this.fieldType === INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET ||
        this.fieldType === INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET ||
        this.fieldType === INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET ||
        this.fieldType === INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET ||
        this.fieldType === INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET
      ) {
        return false;
      }
      return true;
    },

    canAddThresholdPoints() {
      if (
        this.fieldType === INSPECTION_FORM_FIELD_TYPE_CHECK ||
        this.fieldType === INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET ||
        this.fieldType === INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET ||
        this.fieldType === INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET ||
        this.fieldType === INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET ||
        this.fieldType === INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET
      ) {
        return false;
      }
      return true;
    },

    canRemoveThresholdPoints() {
      if (
        this.fieldType === INSPECTION_FORM_FIELD_TYPE_CHECK ||
        this.fieldType === INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET ||
        this.fieldType === INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET ||
        this.fieldType === INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET ||
        this.fieldType === INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET ||
        this.fieldType === INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET
      ) {
        return false;
      }
      return true;
    },

    getThresholdModelData(regenerate = false) {
      if (this.thresholdData.length == 0 || regenerate) {
        let thresholdModelData = [];
        if (this.fieldType === INSPECTION_FORM_FIELD_TYPE_CHECK) {
          thresholdModelData = [
            { option: "YES", points: 0 },
            { option: "NO", points: 0 },
          ];
        } else if (this.fieldType === INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET) {
          thresholdModelData = [
            ...(this.enableNa ? [{ option: "NOT_APPLICABLE", points: 0 }] : []),
            { option: "GOOD", points: 0 },
            { option: "WARNING", points: 0 },
            { option: "BAD", points: 0 },
          ];
        } else if (
          this.fieldType === INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET
        ) {
          thresholdModelData = [
            ...(this.enableNa ? [{ option: "NOT_APPLICABLE", points: 0 }] : []),
            { option: "GOOD", points: 0 },
            { option: "WARNING", points: 0 },
            { option: "BAD", points: 0 },
          ];
        } else if (
          this.fieldType === INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET
        ) {
          thresholdModelData = [
            ...(this.enableNa ? [{ option: "NOT_APPLICABLE", points: 0 }] : []),
            { option: "GOOD", points: 0 },
            { option: "WARNING", points: 0 },
            { option: "BAD", points: 0 },
          ];
        } else if (
          this.fieldType === INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET
        ) {
          thresholdModelData = [
            ...(this.enableNa ? [{ option: "NOT_APPLICABLE", points: 0 }] : []),
            { option: "GOOD", points: 0 },
            { option: "WARNING", points: 0 },
            { option: "BAD", points: 0 },
          ];
        } else if (
          this.fieldType === INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET
        ) {
          thresholdModelData = [
            ...(this.enableNa ? [{ option: "NOT_APPLICABLE", points: 0 }] : []),
            { option: "GOOD", points: 0 },
            { option: "WARNING", points: 0 },
            { option: "BAD", points: 0 },
          ];
        }
        return thresholdModelData;
      }
      return cloneDeep(this.thresholdData);
    },
  },

  watch: {
    fieldType(fieldType) {
      this.thresholdModel = this.getThresholdModelData();
      this.optionsChanged();
    },
    thresholdData(thresholdData) {
      this.thresholdModel = this.getThresholdModelData();
    },
    enableNa() {
      this.thresholdModel = this.getThresholdModelData(true);
      let thresholdData = cloneDeep(this.thresholdModel);
      this.$emit("onThresholdChanged", thresholdData);
    },
  },
};
</script>
