<template>
  <span> {{ fuelTypeJoin }} </span>
</template>

<script>
export default {
  name: "fuel-types",

  components: {},

  mixins: [],

  props: {
    fuelTypes: {
      type: Array | null | undefined,
      default: () => [],
      description: "Fuel types",
    },
  },

  data() {
    return {};
  },

  computed: {
    fuelTypesValue() {
      if (Array.isArray(this.fuelTypes)) {
        return this.fuelTypes.map((fuelType) =>
          this.$t(`FUEL_TYPES.${fuelType.toUpperCase()}`)
        );
      }

      return [];
    },

    fuelTypeJoin() {
      return this.fuelTypesValue.join(", ");
    },
  },

  setup() {},

  created() {},

  methods: {},

  watch: {},
};
</script>
