<template>
  <div class="elite-tabs-wrapper-content">
    <div class="border-bottom">
      <div class="custom-header">
        <base-button
          class="elite-button add"
          icon
          size="sm"
          @click="addReminderModal()"
        >
          <span class="btn-inner--icon">
            <i class="far fa-plus-circle"></i>
          </span>
          <span class="btn-inner--text">{{
            $t("INSPECTION_FORMS.ADD_INSPECTION_FORM_REMINDER")
          }}</span>
        </base-button>
      </div>
    </div>
    <inspection-form-reminders-list
      :inspection-form="inspectionForm"
      @deleteReminder="deleteReminder"
      @editReminder="editReminder"
    />
    <inspection-form-reminder-form-modal
      :loading="loading"
      :reminder-data="reminder"
      :form-errors="formErrors"
      :show-modal="showReminderFormModal"
      @onCloseModal="onCloseModal"
      @reminderFormSubmitted="handleSubmit"
    />
  </div>
</template>

<script>
import InspectionFormRemindersList from "./InspectionFormReminders/InspectionFormRemindersList.vue";
import InspectionFormReminderFormModal from "./InspectionFormReminders/InspectionFormReminderFormModal.vue";
import swal from "sweetalert2";
import requestErrorMixin from "@/mixins/request-error-mixin";
import defaultReminder from "./InspectionFormReminders/defaultInspectionFormReminders.js";

export default {
  name: "inspection-form-view-reminders",

  components: {
    InspectionFormRemindersList,
    InspectionFormReminderFormModal,
  },

  mixins: [requestErrorMixin],

  props: ["inspectionForm"],

  data() {
    return {
      showReminderFormModal: false,
      reminder: null,
      rerenderKey: 0,
      loading: false,
      formErrors: null,
    };
  },

  computed: {},

  created() {},

  methods: {
    addReminderModal() {
      this.reminder = {
        ...defaultReminder,
        order: (this.inspectionForm.reminders.length ?? 0) + 1,
      };
      this.showReminderFormModal = true;
    },

    onCloseModal() {
      this.showReminderFormModal = false;
      this.reminder = null;
      this.$emit("onChangeInspectionForm", true);
      this.rerenderKey++;
    },
    handleSubmit(reminder) {
      this.loading = true;
      const reminderData = {
        ...reminder,
        inspectionForm: {
          type: "inspection-forms",
          id: this.inspectionForm.id,
        },
      };

      if (reminderData.id) {
        this.updateReminder(reminderData);
      } else {
        this.addReminder(reminderData);
      }
    },

    async addReminder(reminder) {
      try {
        await this.$store.dispatch("inspectionFormReminder/add", reminder);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTION_FORMS.INSPECTION_FORM_REMINDER_ADDED"),
        });
        this.onCloseModal();
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
      } finally {
        this.loading = false;
      }
    },

    async updateReminder(reminder) {
      try {
        await this.$store.dispatch("inspectionFormReminder/update", reminder);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTION_FORMS.INSPECTION_FORM_REMINDER_UPDATED"),
        });
        this.onCloseModal();
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
      } finally {
        this.loading = false;
      }
    },

    editReminder(reminder) {
      this.reminder = { ...reminder };
      this.showReminderFormModal = true;
    },

    async deleteReminder(reminder) {
      const confirmation = await swal.fire({
        title: this.$t("INSPECTION_FORMS.DELETE_THIS_INSPECTION_FORM_REMINDER"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          await this.$store.dispatch(
            "inspectionFormReminder/destroy",
            reminder.id
          );
          this.renderKey++;
          this.$emit("onChangeInspectionForm", true);
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t(
              "INSPECTION_FORMS.INSPECTION_FORM_REMINDER_DELETED"
            ),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },

  mounted() {},

  watch: {},
};
</script>
