export const INSPECTION_FORM_FIELD_TYPE_CHECK = "CHECK";
export const INSPECTION_FORM_FIELD_TYPE_SELECT_SINGLE = "SELECT_SINGLE";
export const INSPECTION_FORM_FIELD_TYPE_SELECT_MULTIPLE = "SELECT_MULTIPLE";
export const INSPECTION_FORM_FIELD_TYPE_NUMBER = "NUMBER";
export const INSPECTION_FORM_FIELD_TYPE_TEXT = "TEXT";
export const INSPECTION_FORM_FIELD_TYPE_RANGE_SLIDER = "RANGE_SLIDER";
export const INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET = "STATE_WIDGET";
export const INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET = "TIRE_STATE_WIDGET";
export const INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET =
  "DRUM_THICKNESS_WIDGET";
export const INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET =
  "PAD_THICKNESS_WIDGET";
export const INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET =
  "BATTERY_STATE_WIDGET";

export const inspectionFormFieldOptions = {
  [INSPECTION_FORM_FIELD_TYPE_CHECK]: "INSPECTION_FORMS.FIELD_TYPE_CHECK",
  [INSPECTION_FORM_FIELD_TYPE_SELECT_SINGLE]:
    "INSPECTION_FORMS.FIELD_TYPE_SELECT_SINGLE",
  [INSPECTION_FORM_FIELD_TYPE_SELECT_MULTIPLE]:
    "INSPECTION_FORMS.FIELD_TYPE_SELECT_MULTIPLE",
  [INSPECTION_FORM_FIELD_TYPE_NUMBER]: "INSPECTION_FORMS.FIELD_TYPE_NUMBER",
  [INSPECTION_FORM_FIELD_TYPE_TEXT]: "INSPECTION_FORMS.FIELD_TYPE_TEXT",
  [INSPECTION_FORM_FIELD_TYPE_RANGE_SLIDER]:
    "INSPECTION_FORMS.FIELD_TYPE_RANGE_SLIDER",
  [INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET]:
    "INSPECTION_FORMS.FIELD_TYPE_STATE_WIDGET",
  [INSPECTION_FORM_FIELD_TYPE_TIRE_STATE_WIDGET]:
    "INSPECTION_FORMS.FIELD_TYPE_TIRE_STATE_WIDGET",
  [INSPECTION_FORM_FIELD_TYPE_DRUM_THICKNESS_WIDGET]:
    "INSPECTION_FORMS.FIELD_TYPE_DRUM_THICKNESS_WIDGET",
  [INSPECTION_FORM_FIELD_TYPE_PAD_THICKNESS_WIDGET]:
    "INSPECTION_FORMS.FIELD_TYPE_PAD_THICKNESS_WIDGET",
  [INSPECTION_FORM_FIELD_TYPE_BATTERY_STATE_WIDGET]:
    "INSPECTION_FORMS.FIELD_TYPE_BATTERY_STATE_WIDGET",
};
