<template>
  <el-select
    :placeholder="$t('COMMON.INSPECTION_FORM')"
    v-model="inspectionFormModel"
    @change="inspectionFormModelChanged"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getInspectionForms"
    :loading="loading"
  >
    <el-option
      v-if="showAll"
      :value="null"
      :label="$t('COMMON.ALL_INSPECTION_FORMS')"
    >
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="form in inspectionForms"
      :key="form.id"
      :value="form.id"
      :label="form.name"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "inspection-form-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    inspectionForm: {
      type: String,
      default: null,
      description: "Inspection form id",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterFuelTypes: {
      type: String | Array,
      default: null,
      description: "Filter by fuel type",
    },
  },

  data() {
    return {
      inspectionFormModel: this.inspectionForm,
      inspectionForms: {},
      loading: false,
    };
  },

  setup() {},

  created() {
    if (this.inspectionForm) {
      this.getInspectionForms(null, this.inspectionForm);
    } else {
      this.getInspectionForms("");
    }
  },

  methods: {
    async getInspectionForms(query = null, inspectionFormId = null) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { name: query } : {}),
            ...(inspectionFormId ? { id: inspectionFormId } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }

        if (this.filterFuelTypes && this.filterFuelTypes?.length > 0) {
          params = {
            ...params,
            filter: { ...params.filter, fuel_types: this.filterFuelTypes },
          };
        }

        await this.$store.dispatch("inspectionForms/list", params);
        const inspectionFormArr = await this.$store.getters[
          "inspectionForms/list"
        ];
        this.inspectionForms = {};
        inspectionFormArr.forEach((inspectionForm) => {
          this.inspectionForms[inspectionForm.id] = inspectionForm;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    inspectionFormModelChanged(inspectionForm) {
      this.$emit("inspectionFormChanged", inspectionForm);
    },
  },

  watch: {
    inspectionForm(inspectionForm) {
      if (inspectionForm) {
        this.inspectionFormModel = inspectionForm;
        if (inspectionForm !== this.inspectionFormModel) {
          this.getinspectionForms(null, inspectionForm);
        }
      } else {
        this.inspectionFormModel = null;
      }
    },
    filterOrganization() {
      this.getInspectionForms();
    },
    filterFuelTypes() {
      this.getInspectionForms();
    },
  },
};
</script>
