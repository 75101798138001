<template>
  <div class="tab-pane-inner">
    <div class="inspections-list form-inspection">
      <div class="inspection-title">
        <span>
          {{ `${inspectionForm.name}` }} - {{ $t("COMMON.INSPECTIONS") }}
        </span>
      </div>
      <inspection-list-table :filterInspectionForm="inspectionForm.id" />
    </div>
  </div>
</template>
<script>
import InspectionListTable from "../../InspectionManagement/partials/InspectionListTable.vue";

export default {
  name: "inspection-form-view-logs",

  components: { InspectionListTable },

  props: ["inspectionForm"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
