<template>
  <div>
    <inspection-form-multipliers-list
      :inspectionForm="inspectionForm"
      @onChangeInspectionForm="inspectionFormChanged"
    />
  </div>
</template>

<script>
import { Button, Tooltip } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import InspectionFormMultipliersList from "./InspectionFormMultiplier/InspectionFormMultipliersList.vue";

export default {
  name: "inspection-form-view-multipliers",

  components: {
    InspectionFormMultipliersList,
    [Tooltip.name]: Tooltip,
    [Button.name]: Button,
  },

  mixins: [formMixin],

  props: ["inspectionForm"],

  data() {
    return {};
  },

  computed: {},

  methods: {
    inspectionFormChanged() {
      this.$emit("onChangeInspectionForm", true);
    },
  },

  mounted() {},

  watch: {},
};
</script>
