export default {
  type: "inspection-form-notes",
  min_value: 0,
  max_value: null,
  note: 0,
  relationshipNames: ["inspectionForm"],
  inspectionForm: {
    type: "inspection-forms",
    id: null,
  },
};
