<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!inspectionForm">
      <span class="loader"></span>
    </span>
    <div v-if="inspectionForm" class="elite-tabs-wrapper">
      <div class="tabs-wrapper">
        <tabs
          fill
          class="flex-column flex-md-row"
          tabNavWrapperClasses="nav-wrapper tabs-wrapper-nav"
          tabNavClasses="nav elite-tabs"
          :value="currentTab ?? 'inspectionForm'"
          @onTabChanged="tabChanged"
        >
          <tab-pane title="global" id="1">
            <span slot="title">
              <i class="fa-regular fa-list"></i>
              {{ $t("COMMON.GLOBAL") }}
            </span>
            <inspection-form-view-global
              :inspectionForm="inspectionForm"
              @inspectionFormUpdated="get"
            />
          </tab-pane>

          <tab-pane title="form" id="2" :active="true">
            <span slot="title">
              <i class="fa-regular fa-clipboard-check"></i>
              {{ $t("COMMON.INSPECTION_FORM") }}
            </span>
            <inspection-form-view-form
              :inspectionForm="inspectionForm"
              @onChangeInspectionForm="get"
            />
          </tab-pane>

          <tab-pane title="multipliers" id="3" :active="true">
            <span slot="title">
              <i class="fa-light fa-calculator"></i>
              {{ $t("INSPECTION_FORMS.INSPECTION_FORM_MULTIPLIERS") }}
            </span>
            <inspection-form-view-multipliers
              :inspectionForm="inspectionForm"
              @onChangeInspectionForm="get"
            />
          </tab-pane>

          <tab-pane title="notes" id="4" :active="true">
            <span slot="title">
              <i class="fa-light fa-pen"></i>
              {{ $t("INSPECTION_FORMS.INSPECTION_FORM_NOTES") }}
            </span>
            <inspection-form-view-notes
              :inspectionForm="inspectionForm"
              @onChangeInspectionForm="get"
            />
          </tab-pane>

          <tab-pane title="reminders" id="5" :active="true">
            <span slot="title">
              <i class="fa-regular fa-bell-on"></i>
              {{ $t("INSPECTION_FORMS.INSPECTION_FORM_REMINDERS") }}
            </span>
            <inspection-form-view-reminders
              :inspectionForm="inspectionForm"
              @onChangeInspectionForm="get"
            />
          </tab-pane>

          <tab-pane title="inspections" id="6">
            <span slot="title">
              <i class="fa-regular fa-magnifying-glass"></i>
              {{ $t("COMMON.INSPECTIONS") }}
            </span>
            <inspection-form-view-inspections
              :inspectionForm="inspectionForm"
            />
          </tab-pane>

          <tab-pane
            title="files"
            id="998"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
          >
            <span slot="title">
              <i class="far fa-file-alt"></i>
              {{ $t("COMMON.FILES") }}
            </span>
            <div class="elite-tabs-wrapper-content">
              <list-file-component :object="inspectionForm" />
            </div>
          </tab-pane>

          <tab-pane
            title="logs"
            id="999"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
          >
            <span slot="title">
              <i class="fa fa-history"></i>
              {{ $t("COMMON.LOGS") }}
            </span>
            <inspection-form-view-logs :inspectionForm="inspectionForm" />
          </tab-pane>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import InspectionFormViewForm from "../partials/InspectionFormViewForm.vue";
import InspectionFormViewInspections from "../partials/InspectionFormViewInspections.vue";
import InspectionFormViewLogs from "../partials/InspectionFormViewLogs.vue";
import InspectionFormViewGlobal from "../partials/InspectionFormViewGlobal.vue";
import InspectionFormViewMultipliers from "../partials/InspectionFormViewMultipliers.vue";
import InspectionFormViewNotes from "../partials/InspectionFormViewNotes.vue";
import InspectionFormViewReminders from "../partials/InspectionFormViewReminders.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";
import hasTabs from "@/mixins/has-tabs";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    InspectionFormViewLogs,
    ListFileComponent,
    InspectionFormViewGlobal,
    InspectionFormViewForm,
    InspectionFormViewInspections,
    InspectionFormViewMultipliers,
    InspectionFormViewNotes,
    InspectionFormViewReminders,
    Tabs,
    TabPane,
  },

  mixins: [requestErrorMixin, hasTabs],

  props: {
    inspectionFormId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      inspectionForm: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch(
          "inspectionForms/get",
          this.inspectionFormId
        );
        this.inspectionForm =
          this.$store.getters["inspectionForms/inspectionForm"];
        this.$emit("inspectionFormLoaded", this.inspectionForm);
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
