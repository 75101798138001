<template>
  <div class="conditional">
    <div class="inspection-form-group width-1">
      <base-checkbox v-model="modelData.conditional_field">
        <span class="form-control-label">
          {{ $t("INSPECTION_FORMS.CONDITIONAL_FIELD") }}
          <span class="check">
            <i class="far fa-check"></i>
          </span>
        </span>
      </base-checkbox>
      <validation-error :errors="apiValidationErrors.conditional_field" />
    </div>
    <div
      class="inspection-form-group width-1_2"
      v-if="modelData.conditional_field"
    >
      <base-input
        :label="`${$t('INSPECTION_FORMS.CONDITION_SHOW')} (*)`"
        :placeholder="`${$t('INSPECTION_FORMS.CONDITION_SHOW')} (*)`"
        input-classes="form-control-alternative"
      >
        <el-select
          class="select-primary"
          v-model="modelData.condition_show"
          :placeholder="$t('INSPECTION_FORMS.CONDITION_SHOW')"
        >
          <el-option
            class="select-primary"
            :label="$t('INSPECTION_FORMS.SHOW')"
            :value="'SHOW'"
          >
          </el-option>
          <el-option
            class="select-primary"
            :label="$t('INSPECTION_FORMS.HIDE')"
            :value="'HIDE'"
          >
          </el-option>
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.condition_show" />
    </div>
    <div
      class="inspection-form-group width-1_2"
      v-if="modelData.conditional_field"
    >
      <base-input
        :label="`${$t('INSPECTION_FORMS.CONDITION_RELATION')} (*)`"
        :placeholder="`${$t('INSPECTION_FORMS.CONDITION_RELATION')} (*)`"
        input-classes="form-control-alternative"
      >
        <el-select
          class="select-primary"
          v-model="modelData.condition_relation"
          :placeholder="$t('INSPECTION_FORMS.CONDITION_RELATION')"
        >
          <el-option
            class="select-primary"
            :label="$t('INSPECTION_FORMS.RELATION_AND')"
            :value="'AND'"
          >
          </el-option>
          <el-option
            class="select-primary"
            :label="$t('INSPECTION_FORMS.RELATION_OR')"
            :value="'OR'"
          >
          </el-option>
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.condition_relation" />
    </div>
    <div
      class="inspection-form-group width-1"
      v-if="modelData.conditional_field"
    >
      <div class="conditions-group">
        <div
          v-for="(line, key) in modelData.conditions"
          :key="key"
          class="conditions-group-wrapper"
        >
          <div class="conditions-group-wrapper-item">
            <base-input
              :label="`${$t('INSPECTION_FORMS.FIELD')} (*)`"
              input-classes="form-control-alternative"
            >
              <inspection-form-section-field-selector
                :field="line.field"
                :filterInspectionForm="inspectionForm.id"
                :allowAll="false"
                :allowNone="false"
                @fieldChanged="(fieldId) => (line.field = fieldId)"
              />
            </base-input>
          </div>
          <div class="conditions-group-wrapper-item">
            <base-input
              :label="`${$t('INSPECTION_FORMS.OPERATOR')} (*)`"
              :placeholder="`${$t('INSPECTION_FORMS.OPERATOR')} (*)`"
              input-classes="form-control-alternative"
            >
              <el-select
                class="select-primary"
                v-model="line.operator"
                :placeholder="$t('INSPECTION_FORMS.OPERATOR')"
              >
                <el-option
                  class="select-primary"
                  :label="$t('INSPECTION_FORMS.OPERATOR_EQUALS')"
                  :value="'=='"
                ></el-option>
                <el-option
                  class="select-primary"
                  :label="$t('INSPECTION_FORMS.OPERATOR_DIFFERENT')"
                  :value="'!='"
                >
                </el-option>
                <el-option
                  class="select-primary"
                  :label="$t('INSPECTION_FORMS.OPERATOR_SUPERIOR')"
                  :value="'>'"
                >
                </el-option>
                <el-option
                  class="select-primary"
                  :label="$t('INSPECTION_FORMS.OPERATOR_INFERIOR')"
                  :value="'<'"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="conditions-group-wrapper-item">
            <base-input
              :label="$t('INSPECTION_FORMS.VALUE')"
              :placeholder="$t('INSPECTION_FORMS.VALUE')"
              v-model="line.value"
              :inputClasses="'extension-input'"
            />
          </div>
          <div class="conditions-group-wrapper-item remove">
            <base-button
              type="button"
              class="form-control btn"
              @click.prevent="() => removeCondition(key)"
            >
              <i class="fal fa-times"></i>
            </base-button>
          </div>
        </div>
        <base-button
          type="button"
          class="btn add"
          @click.prevent="addCondition"
        >
          <i class="far fa-plus"></i>
          <span>Ajouter</span>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from "lodash";
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";
import InspectionFormSectionFieldSelector from "@/components/InspectionFormSectionFieldSelector.vue";

export default {
  name: "conditional-field-selector",

  components: {
    ValidationError,
    InspectionFormSectionFieldSelector,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["model", "inspectionForm"],

  data() {
    return {
      modelData: cloneDeep(this.model),
    };
  },

  computed: {},

  methods: {
    addCondition() {
      if (!this.modelData.conditions) {
        this.modelData.conditions = [];
      }
      this.modelData.conditions.push({
        field: null,
        operator: "==",
        value: null,
      });
    },
    removeCondition(key) {
      this.modelData.conditions = this.modelData.conditions.filter(
        (_, idx) => idx !== key
      );
    },
  },

  mounted() {},

  watch: {
    model() {
      if (!isEqual(this.model, this.modelData)) {
        this.modelData = cloneDeep(this.model);
      }
    },
    modelData: {
      handler: function () {
        this.$emit("conditionsChanged", this.modelData);
      },
      deep: true,
    },
  },
};
</script>
