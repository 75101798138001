<template>
  <div
    v-if="showModal"
    class="resizable-wrapper"
    v-bind:class="[showModal ? 'show' : 'hide']"
  >
    <div
      class="resizable-wrapper-content"
      v-resizable-modal="'INSPECTION_FORM_SECTION'"
      @onCloseModal="closeInspectionFormSectionModal"
    >
      <div class="resizable-wrapper-content-header">
        <div class="resizable-wrapper-content-header-left">
          <base-button
            icon
            size="sm"
            class="back-button"
            @click="closeInspectionFormSectionModal"
          >
            <i class="fal fa-arrow-left"></i>
          </base-button>
          <h1 v-if="!inspectionFormSection.id">
            {{ $t("INSPECTION_FORMS.NEW_INSPECTION_FORM_SECTION") }}
          </h1>
          <h1 v-if="!!inspectionFormSection.id">
            {{ $t("INSPECTION_FORMS.EDIT_INSPECTION_FORM_SECTION") }}
          </h1>
        </div>
        <div class="resizable-wrapper-content-header-right">
          <button class="btn expand">
            <img
              src="../../../../../../../public/img/sm-arrows-expand.svg"
              alt="icon"
            />
          </button>
        </div>
      </div>
      <div class="resizable-wrapper-content-body">
        <div class="section-form-wrapper">
          <div class="inspection-form">
            <div class="inspection-form-group width-1">
              <base-input
                :label="`${$t('COMMON.NAME')} (*)`"
                :placeholder="$t('COMMON.NAME')"
                v-model="inspectionFormSectionModel.name"
                input-classes="form-control-alternative"
              >
              </base-input>
              <validation-error :errors="apiValidationErrors.name" />
            </div>
            <div class="inspection-form-group width-1">
              <base-input
                :label="`${$t('INSPECTION_FORMS.FACTOR')} (*)`"
                :placeholder="$t('INSPECTION_FORMS.FACTOR')"
                v-model="inspectionFormSectionModel.factor"
                input-classes="form-control-alternative"
                type="number"
                step="1"
              >
              </base-input>
              <validation-error :errors="apiValidationErrors.factor" />
            </div>
            <div class="inspection-form-group width-1">
              <base-input
                :label="`${$t('COMMON.EXCERPT')} (*)`"
                :placeholder="`${$t('COMMON.EXCERPT')} (*)`"
                input-classes="form-control-alternative"
              >
                <html-editor
                  v-model="inspectionFormSectionModel.excerpt"
                  :name="`${$t('COMMON.EXCERPT')} (*)`"
                />
              </base-input>
              <validation-error :errors="apiValidationErrors.excerpt" />
            </div>
            <div class="inspection-form-group width-1">
              <image-selector
                ressource_name="inspection-form-field"
                :defaultImage="inspectionFormSectionModel.icon"
                :ressource_id="
                  inspectionFormSectionModel.id
                    ? inspectionFormSectionModel.id
                    : 0
                "
                field="icon"
                :label="$t('INSPECTION_FORMS.FIELD_ICON')"
                @imageChanged="
                  (file_url) => {
                    inspectionFormSectionModel.icon = file_url;
                  }
                "
              />
              <validation-error :errors="apiValidationErrors.icon" />
            </div>

            <conditional-field-selector
              :model="inspectionFormSectionModel"
              :inspectionForm="inspectionForm"
              @conditionsChanged="
                (model) => {
                  inspectionFormSectionModel = {
                    ...inspectionFormSectionModel,
                    ...model,
                  };
                }
              "
            />

            <div class="inspection-form-group width-1 next-button">
              <base-button
                size="sm"
                v-if="!inspectionFormSectionModel.id"
                @click="addInspectionFormSection"
              >
                <span v-if="loading" class="btn-inner--icon">
                  <i class="fas fa-spinner fa-spin"></i>
                </span>
                {{ $t("INSPECTION_FORMS.ADD_INSPECTION_FORM_SECTION") }}
                <i class="far fa-arrow-right"></i>
              </base-button>

              <base-button
                size="sm"
                v-if="inspectionFormSectionModel.id"
                @click="editInspectionFormSection"
              >
                <span v-if="loading" class="btn-inner--icon">
                  <i class="fas fa-spinner fa-spin"></i>
                </span>
                {{ $t("INSPECTION_FORMS.UPDATE_INSPECTION_FORM_SECTION") }}
                <i class="far fa-arrow-right"></i>
              </base-button>

              <base-button
                size="sm"
                class="transparent"
                @click="closeInspectionFormSectionModal"
                :disabled="loading"
              >
                {{ $t("COMMON.CLOSE") }}
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ImageSelector from "@/components/ImageSelector.vue";
import ConditionalFieldSelector from "../ConditionalFieldSelector.vue";
import defaultInspectionFormSection from "./defaultInspectionFormSection";

export default {
  name: "inspection-form-section-form",

  components: {
    HtmlEditor,
    ValidationError,
    ImageSelector,
    ConditionalFieldSelector,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin, requestErrorMixin],

  props: [
    "inspectionForm",
    "inspectionFormSection",
    "showModal",
    "loading",
    "formErrors",
  ],

  data() {
    return {
      inspectionFormSectionModel: cloneDeep(defaultInspectionFormSection),
    };
  },

  computed: {},

  methods: {
    closeInspectionFormSectionModal() {
      this.$emit("onCloseInspectionFormSectionModal", true);
    },

    addInspectionFormSection() {
      const inspectionFormSectionData = cloneDeep(
        this.inspectionFormSectionModel
      );
      this.$emit("onAddInspectionFormSectionForm", inspectionFormSectionData);
    },

    editInspectionFormSection() {
      const inspectionFormSectionData = cloneDeep(
        this.inspectionFormSectionModel
      );
      this.$emit("onEditInspectionFormSectionForm", inspectionFormSectionData);
    },

    addCondition() {
      if (!this.inspectionFormSectionModel.conditions) {
        this.inspectionFormSectionModel.conditions = [];
      }
      this.inspectionFormSectionModel.conditions.push({
        field: null,
        operator: "=",
        value: null,
      });
      this.conditionsChanged();
    },
    removeCondition(key) {
      this.inspectionFormSectionModel.conditions =
        this.inspectionFormSectionModel.conditions.filter(
          (_, idx) => idx !== key
        );
      this.conditionsChanged();
    },
    conditionsChanged() {
      const conditionsData = this.inspectionFormSectionModel.conditions.map(
        (item) => {
          return {
            field: item.field,
            operator: item.operator,
            value: item.value,
          };
        }
      );
      this.$emit("conditionNumbersChanged", conditionsData);
    },
  },

  mounted() {},

  watch: {
    inspectionFormSection(inspectionFormSection) {
      if (inspectionFormSection) {
        this.inspectionFormSectionModel = cloneDeep(inspectionFormSection);
      }
      this.resetApiValidation();
    },
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
