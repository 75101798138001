<template>
  <div class="tab-pane-inner">
    <div class="list-table-inner-body">
      <div class="list-table-inner-body-inner">
        <el-table
          class="align-items-center table-flush inspections-forms"
          header-row-class-name="thead-light"
          :data="reminders"
        >
          <!-- <el-table-column
            :label="$t('INSPECTION_FORMS.INSPECTION_FORM_REMINDER_ORDER')"
            prop="order"
            min-width="220"
          >
            <template v-slot="{ row }">
              <span>{{ row.order }}</span>
            </template>
          </el-table-column> -->

          <el-table-column
            :label="$t('INSPECTION_FORMS.INSPECTION_FORM_REMINDERS')"
            prop="excerpt"
            min-width="220"
          >
            <template v-slot="{ row }">
              <span>{{ removeHTMLTags(row.excerpt) ?? "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :fixed="$listActionsButtonsPosition()"
            min-width="120"
          >
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip :content="$t('COMMON.EDIT')" placement="top">
                <a
                  class="table-action"
                  data-toggle="tooltip"
                  type="text"
                  @click="editReminder(row)"
                >
                  <i class="fal fa-edit"></i>
                </a>
              </el-tooltip>

              <el-tooltip :content="$t('COMMON.DELETE')" placement="top">
                <a
                  type="text"
                  class="table-action table-action-delete"
                  @click="deleteReminder(row)"
                  data-toggle="tooltip"
                >
                  <i class="fal fa-trash-alt"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";

export default {
  name: "inspection-form-reminders-list",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  props: ["inspectionForm"],

  data() {
    return {};
  },

  computed: {
    reminders: function () {
      return this.inspectionForm.reminders;
    },
  },

  methods: {
    deleteReminder(reminder) {
      this.$emit("deleteReminder", reminder);
    },
    editReminder(reminder) {
      this.$emit("editReminder", reminder);
    },
    removeHTMLTags(text) {
      let div = document.createElement("div");
      div.innerHTML = text;
      text = div.textContent || div.innerText || "";

      return text;
    },
  },

  mounted() {},

  watch: {
    inspectionForm(inspectionForm) {},
  },
};
</script>
